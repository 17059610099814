import React, { useState, useContext } from 'react';
import BaseTreeItem from './BaseTreeItem';
import { Box, Typography, Modal, Button, TextField, TextareaAutosize, Skeleton, Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import EngineeringIcon  from '@mui/icons-material/Engineering';
import { Area } from '../../Model/Area';
import AreaItem from './AreaItem';
import { TreeContext } from './TreeContext';
import { Department } from '../../Model/Department';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import { ApiUrl } from '../../ApiUrl';
import { useAuth } from "../../Hooks/AuthProvider";

const DepartmentItem = ({ label, nodeId, departmentId, onEdit }) => {
    const auth = useAuth();
    const [areas, setAreas] = useState([]);
    const { expanded, setExpanded } = useContext(TreeContext);
    const [isApiCalled, setIsApiCalled] = useState(false);

    const [isAreaModalOpen, setIsAreaModalOpen] = useState(false);
    const [isAreaModaEdit, setIsisAreaModaEdit] = useState(false);
    const [open, setOpen] = useState(false);
    const [areaEditId, setAreaEditId] = useState(0);
    const handleOpen = () => { setIsAreaModalOpen(true); setIsisAreaModaEdit(false); };
    const handleClose = () => {
        setIsAreaModalOpen(false);
        setIsisAreaModaEdit(false);
      setFormName('');
      setFormCode('');
      setFormDesc('');
      setFormNameError(false);
    };
    const handleEditModalOpen = (area) => {
        setIsAreaModalOpen(true);
        setIsisAreaModaEdit(true);
        setFormCode(area.code);
        setFormName(area.name);
        setFormDesc(area.description)
    }
    const handleChildEdit = (areaId) => {
        setAreaEditId(areaId)
        const area = areas.find(x => x.id === areaId)
        handleEditModalOpen(area)
    }

    const handleCloseSnackbar = (reason) => {
      if (reason === 'clickaway') {
          return;
      }
      setOpen(false);
  };

  const [formName, setFormName] = useState('');
  const [formCode, setFormCode] = useState('');
  const [typeAlert, setTypeAlert] = useState('');
  const [messageAlert, setMessageAlert] = useState('');
  const [formDesc, setFormDesc] = useState('');
  const [formNameError, setFormNameError] = useState(false);

  const handleNameChange = (event) => {
    setFormName(event.target.value);
    };

    const handleCodeChange = (event) => {
        setFormCode(event.target.value);
    };

    const handleDescChange = (event) => {
        setFormDesc(event.target.value);
    };

  const handleAreaSave = async () => {
    const area = new Area();
    const department =  new Department();
    department.id = departmentId;
    area.department = department;
    area.name = formName;
    area.code = formCode;
    area.description = formDesc;
      if (auth) {
          area.createdBy = auth.user?.id;
      }
    let isNameError = false;

    if(!formName.trim()) {
      setFormNameError(true);
      isNameError = true;
    } else {
      setFormNameError(false);
      isNameError = false;
    }

    if(isNameError) {
      return;
    }

    fetch(ApiUrl.areaEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(area),
    })
    .then(() => {
      handleClose();
      getData();
      setOpen(true);
      setTypeAlert("success");
      setMessageAlert("Area added successfully");
    })
    .catch(error => {
      console.error(error);
      setOpen(true);
      setTypeAlert("error");
      setMessageAlert("Error adding Area");
    });
  }

  const handleAreaUpdate = async () => {
    const area = new Area();
    const department =  new Department();
      department.id = departmentId;
      area.id = areaEditId;
    area.department = department;
    area.name = formName;
    area.code = formCode;
    area.description = formDesc;
      if (auth) {
          area.modifiedBy = auth.user?.id;
      }
    let isNameError = false;

    if(!formName.trim()) {
      setFormNameError(true);
      isNameError = true;
    } else {
      setFormNameError(false);
      isNameError = false;
    }

    if(isNameError) {
      return;
    }

    fetch(ApiUrl.areaEndpoint, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(area),
    })
    .then(() => {
      handleClose();
      getData();
      setOpen(true);
      setTypeAlert("success");
      setMessageAlert("Area updated successfully");
    })
    .catch(error => {
      console.error(error);
      setOpen(true);
      setTypeAlert("error");
      setMessageAlert("Error updating Area");
    });
  }

  const handleEdit = () => {
      onEdit(departmentId)
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  function getData() {
    fetch(`${ApiUrl.areaEndpoint}/GetAllByDepartment/${departmentId}`)
      .then(response => response.json())
      .then(json => setData(json))
      .catch(error => console.error(error));
  }

  const handleClick = async () => {
    if (!isApiCalled) {
      getData();
    }

    const currentIndex = expanded.indexOf(nodeId);
    const newExpanded = [...expanded];

    if (currentIndex === -1) {
      newExpanded.push(nodeId);
    } else {
      newExpanded.splice(currentIndex, 1);
    }

    setExpanded(newExpanded);
  };

  function setData(json) {
    const areas = json.map(item => Area.fromJSON(item));
    setIsApiCalled(true);
    setAreas(areas);
    setExpanded((prevExpanded) => [...prevExpanded, String(nodeId)]);
  }

  const itemLabel = (
    <Box display="flex" alignItems="center" style={{ padding: '15px' }}>
      <Box display="flex" alignItems="center" flexGrow={1}>
        <EngineeringIcon />
        <Typography variant="body1" style={{ marginLeft: 10 }}>{label}</Typography>
      </Box>
      <Tooltip placement="bottom" title="Add a new Area">
        <AddIcon onClick={handleOpen} style={{ marginRight: "15px" }} />
      </Tooltip>
      <Tooltip placement="bottom" title="Edit this Department">
        <EditIcon onClick={handleEdit} style={{ marginRight: "15px" }} />
      </Tooltip>
    </Box>
  );

  return (
    <Box>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={4000} onClose={handleCloseSnackbar}>
          <Alert severity={typeAlert}>
              {messageAlert}
          </Alert>
      </Snackbar>
      <BaseTreeItem nodeId={nodeId} label={itemLabel} onClick={handleClick}>
        {areas.map(area => (
            <AreaItem onEdit={handleChildEdit} key={`2${area.id}`} nodeId={`2${area.id}`} areaId={area.id} label={`${area.name}${area.code ? ` (${area.code})` : ''}`} />
          ))}
      </BaseTreeItem>
      <Modal
        open={isAreaModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" component="h2">{isAreaModaEdit ? "Edit Area" : "Add a new Area"}</Typography>
          <form>
            <Box display="flex" alignItems="center"  style={{ marginBottom: 8}}>
              <TextField
                error={formNameError}
                helperText={formNameError ? "Name is required" : ""}
                label="Name"
                variant="outlined"
                margin="normal"
                fullWidth
                style={{ marginRight: 15 }}
                value={formName}
                onChange={handleNameChange}
              />
            </Box>
            <Box display="flex" alignItems="center" style={{ marginBottom: 8}}>
                <TextField
                    label="Code"
                    variant="outlined"
                    fullWidth
                    style={{ marginRight: 15 }}
                    value={formCode}
                    onChange={handleCodeChange}
                />
            </Box>
            <Box display="flex" alignItems="center" style={{ marginTop: 16}}>
                <TextareaAutosize
                    placeholder="Description"
                    variant="outlined"
                    style={{
                        width: '100%',
                        marginRight: 15,
                        minHeight: '56px',
                        padding: '8px',
                        border: '1px solid #ced4da',
                        borderRadius: '4px',
                        resize: 'none',
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                    }}
                    value={formDesc}
                    onChange={handleDescChange}
                />
            </Box>
                <Box marginTop={3} display="flex" alignItems="center">
                    {isAreaModaEdit ? 
                        <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleAreaUpdate}>
                            <SaveIcon style={{ marginRight: '15px' }} />Update
                        </Button>:
                        <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleAreaSave}>
                            <SaveIcon style={{ marginRight: '15px' }} />Save
                        </Button>
                    }


              <Button variant="contained" color="error" disableElevation onClick={handleClose}>Close</Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default DepartmentItem;
