import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Modal, Select, MenuItem, Skeleton, Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import * as XLSX from 'xlsx';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import appColor from '../../Style/appColor';
import EditIcon from '@mui/icons-material/Edit';
import { ApiUrl } from '../../ApiUrl';
import { AuditType } from '../../Model/Enum/AuditType';
import { AuditStatus } from '../../Model/Enum/AuditStatus';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { AuditStatusDto } from '../../Model/Dto/AuditStatusDto';
import { GetApp } from '@mui/icons-material';
import { convertDate } from '../../Utils/convertDate';

function AuditProgrammingPage() {
    const navigate = useNavigate();
    const [audits, setAudits] = useState([]);

    const [auditStatuses, setAuditStatuses] = useState([]);
    const [auditSelectedTitle, setAuditSelectedTitle] = useState("");
    const [auditSelectedCurrentStatus, setAuditSelectedCurrentStatus] = useState("");
    const [auditSelectedStatus, setAuditSelectedStatus] = useState("");
    const [typeAlert, setTypeAlert] = useState("");
    const [messageAlert, setMessageAlert] = useState("");
    const [auditSelectedId, setAuditSelectedId] = useState(0);

    const [isUpdateStatusModalOpen, setIsUpdateStatusModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);

    const handleUpdateStatusModalOpen = (row) => { getAllAuditStatus(); setAuditSelectedId(row.auditId); setAuditSelectedTitle(row.auditName); setAuditSelectedCurrentStatus(row.auditStatus); setAuditSelectedStatus(row.auditStatus); setIsUpdateStatusModalOpen(true); }
    const handleUpdateStatusModalClose = () => { setIsUpdateStatusModalOpen(false); setAuditSelectedId(0); setAuditSelectedTitle(""); setAuditSelectedCurrentStatus(""); setAuditSelectedStatus(""); }

    const handleDeleteModalOpen = (row) => { setIsDeleteModalOpen(true); setAuditSelectedId(row.auditId); }
    const handleDeleteModalClose = () => { setIsDeleteModalOpen(false); setAuditSelectedId(0); }

    const handleCloseSnackbar = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <Button style={{ cursor: 'pointer', margin: '0 10px' }} onClick={() => exportToExcel()}>
                    <GetApp style={{ marginRight: 10 }} />
                    EXPORT
                </Button>
            </GridToolbarContainer>
        );
    }

    const handleAuditStatusUpdate = () => {
        setOpen(true);
        handleUpdateStatusModalClose();
        setLoading(true);
        const entries = Object.entries(AuditStatus);
        const foundEntry = entries.find(([_, value]) => value === auditSelectedStatus);

        const auditStatusDto = new AuditStatusDto();
        auditStatusDto.auditId = auditSelectedId;
        auditStatusDto.statusId = parseInt(foundEntry[0]);

        fetch(ApiUrl.auditEndpoint + "/AuditStatus", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(auditStatusDto),
        })
            .then(() => {
                setLoading(false);
                setTypeAlert("success");
                setMessageAlert("Status changed with success!");
                getAuditList();
            })
            .catch(error => {
                console.error(error);
                setOpen(true);
                setTypeAlert("error");
                setMessageAlert("Status change filed!");
                setLoading(false);
            });
    }

    const handleCheckListDelete = () => {
        fetch(ApiUrl.auditEndpoint + "/" + auditSelectedId, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(() => {
                handleDeleteModalClose();
                getAuditList();
                setOpen(true);
                setTypeAlert("success");
                setMessageAlert("Deleted checklist with success");
            })
            .catch(error => {
                console.log(error);
                setOpen(true);
                setTypeAlert("error");
                setMessageAlert("Error deleting audit");
            });

    }

    useEffect(getAuditList, []);

    function getAuditList() {
        setLoading(true);
        fetch(ApiUrl.auditEndpoint + "/GetAllFromView?statuses=1,2,3,4,5,6,7,8")
            .then(response => response.json())
            .then(json => {
                console.log("json", json)
                setAudits(json)
                setLoading(false);
            })
            .catch(error => {
                console.error(error)
                setLoading(false);
            });
    }

    function getAllAuditStatus() {
        fetch(ApiUrl.auditEndpoint + "/AllAuditStatus")
            .then(response => response.json())
            .then(json => setAuditStatuses(json))
            .catch(error => {
                setLoading(false);
                console.error(error);
            });
    }

    const navigateToCreateAuditPage = () => navigate('create-audit/0');

    const exportToExcel = () => {
        debugger;
        const headers = columns.filter(column => column.field !== 'action').map(column => column.headerName);

        const worksheet = XLSX.utils.json_to_sheet([headers, ...audits.map(rowData =>
            columns.map(column => {
                switch (column.field) {
                    case 'status':
                        return AuditStatus[rowData[column.field]];
                    case 'type':
                        return AuditType[rowData[column.field]];
                    default:
                        return column.valueGetter ? column.valueGetter({ row: rowData }) : rowData[column.field];
                }
            })
        )], { skipHeader: true });

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'AuditList');
        XLSX.writeFile(workbook, 'AuditList.xlsx');
    };

    const columns = [
        {
            field: 'auditId',
            headerName: 'Id',
            width: 70,
            renderCell: (params) => params.row.auditId
        },
        {
            field: 'auditName',
            headerName: 'Title',
            width: 150,
            renderCell: (params) => params.row.auditName
        },
        {
            field: 'auditStatus',
            headerName: 'Status',
            width: 100,
            renderCell: (params) => params.row.auditStatus
        },
        {
            field: 'auditType',
            headerName: 'Type',
            width: 90,
            renderCell: (params) => params.row.auditType
        },
        {
            field: 'plantName',
            headerName: 'Plant',
            width: 120,
            valueGetter: (params) => params.row.plantName
        },
        {
            field: 'departmentName',
            headerName: 'Department',
            width: 120,
            valueGetter: (params) => params.row.departmentName
        },
        {
            field: 'areaNames',
            headerName: 'Area',
            width: 120,
            valueGetter: (params) => params.row.areaNames
        },
        {
            field: 'lineNames',
            headerName: 'Line',
            width: 120,
            valueGetter: (params) => params.row.lineNames
        },
        {
            field: 'machineNames',
            headerName: 'Machine',
            width: 120,
            valueGetter: (params) => params.row.machineNames
        },
        {
            field: 'auditStartDate',
            headerName: 'Start Date',
            width: 120,
            valueGetter: (params) => convertDate(params.row.auditStartDate)
        },
        {
            field: 'endDate',
            headerName: 'End Date',
            width: 120,
            valueGetter: (params) => convertDate(params.row.endDate)
        },
        {
            field: 'dateCompleted',
            headerName: 'Date Completed',
            width: 120,
            valueGetter: (params) => convertDate(params.row.dateCompleted)
        },
        {
            field: 'filingDate',
            headerName: 'Filing Date',
            width: 120,
            valueGetter: (params) => convertDate(params.row.filingDate)
        },
        {
            field: 'leadAuditorEmail',
            headerName: 'Lead Auditor',
            width: 150,
            valueGetter: (params) => params.row.leadAuditorEmail
        },
        {
            field: 'countdown',
            headerName: 'Countdown',
            width: 130,
            valueGetter: (params) => params.row.countdown + " days"
        },
        {
            field: 'checkListCount',
            headerName: 'CheckList Count',
            width: 120
        },
        {
            field: 'action',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => (
                <div>
                    <EditIcon style={{ cursor: 'pointer' }} onClick={() => navigate('edit-audit/' + params.row.auditId)} />
                    <TaskAltIcon style={{ cursor: 'pointer', marginLeft: 8 }} onClick={() => handleUpdateStatusModalOpen(params.row)} />
                    <DeleteIcon style={{ cursor: 'pointer', marginLeft: 8 }} onClick={() => handleDeleteModalOpen(params.row)} />
                </div>
            )
        }
    ];

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Box>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={4000} onClose={handleCloseSnackbar}>
                <Alert severity={typeAlert}>
                    {messageAlert}
                </Alert>
            </Snackbar>
            <Box display="flex" alignItems="center" style={{ marginBottom: 20 }}>
                <Typography variant="h4" style={{ marginRight: 30 }}>Audit Programming</Typography>
                <Button variant="contained" disableElevation onClick={navigateToCreateAuditPage}>
                    <AddIcon style={{ marginRight: '15px' }} />Add new Audit
                </Button>
            </Box>
            {loading ? (
                <>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                </>

            ) : (
                audits.length === 0 ?
                    <Typography variant="h6" style={{ marginRight: 30 }}>No Audit yet</Typography> :
                    <DataGrid
                        style={{ backgroundColor: appColor.white }}
                        rows={audits}
                        columns={columns}
                        getRowId={(row) => row.auditId}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        slots={{
                            toolbar: CustomToolbar,
                        }}
                        pageSizeOptions={[10]}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        disableRowSelectionOnClick
                    />)}

            <Modal
                open={isUpdateStatusModalOpen}
                onClose={handleUpdateStatusModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2" marginBottom={2}>Update this Audit status</Typography>
                    <p style={{ marginBottom: 20 }}>Audit Title: {auditSelectedTitle} | Current status: {auditSelectedCurrentStatus}</p>
                    <Select
                        fullWidth
                        value={auditSelectedStatus}
                        onChange={(event) => setAuditSelectedStatus(event.target.value)}
                    >
                        {auditStatuses.map((status) => <MenuItem key={status} value={status}>{status}</MenuItem>)}
                    </Select>
                    <Box marginTop={3} display="flex" alignItems="center">
                        <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleAuditStatusUpdate}>
                            <SaveIcon style={{ marginRight: '15px' }} />Update
                        </Button>
                        <Button variant="contained" color="error" disableElevation onClick={handleUpdateStatusModalClose}>Close</Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={isDeleteModalOpen}
                onClose={handleDeleteModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2" marginBottom={2}>This operation will delete sensible data</Typography>
                    <p style={{ marginBottom: 15 }}><strong>[ ! ] Pay attention, this operation will delete sensible data [ ! ]</strong></p>
                    <p>By confirming this operation you will delete all occurrencies of this data with ID {auditSelectedId}:</p>
                    <ul style={{ marginLeft: 20 }}>
                        <li>Audit</li>
                        <li>Machine added to Audit</li>
                        <li>User added to Audit</li>
                        <li>Line added to Audit</li>
                        <li>CheckList added to Audit</li>
                        <li>Area added to Audit</li>
                    </ul>
                    <Box marginTop={3} display="flex" alignItems="center">
                        <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleCheckListDelete}>
                            <SaveIcon style={{ marginRight: '15px' }} />Confirm
                        </Button>
                        <Button variant="contained" color="error" disableElevation onClick={handleDeleteModalClose}>Close</Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}

export default AuditProgrammingPage;
