import React, { useState, useContext } from 'react';
import BaseTreeItem from './BaseTreeItem';
import { Box, Typography, Modal, Button, TextField, TextareaAutosize, Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import FactoryIcon from '@mui/icons-material/Factory';
import AddIcon from '@mui/icons-material/Add';
import DepartmentItem from './DepartmentItem';
import { Department } from '../../Model/Department';
import { TreeContext } from './TreeContext';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { Plant } from '../../Model/Plant';
import { ApiUrl } from '../../ApiUrl';
import { useAuth } from "../../Hooks/AuthProvider";

const PlantItem = ({ label, nodeId, plantId, onEdit }) => {
    const auth = useAuth();
    const [departments, setDepartments] = useState([]);
    const { expanded, setExpanded } = useContext(TreeContext);
    const [isApiCalled, setIsApiCalled] = useState(false);

    const [isDepartmentModalOpen, setIsDepartmentModalOpen] = useState(false);
    const [isDepartmentModaEdit, setIsisDepartmentModaEdit] = useState(false);
    const [open, setOpen] = useState(false);
    const [departmentEditId, setDepartmentEditId] = useState(0);
    const handleOpen = () => {
        setIsDepartmentModalOpen(true);
        setIsisDepartmentModaEdit(false)
    };
    const handleEditModalOpen = (department) => {
        setIsDepartmentModalOpen(true);
        setIsisDepartmentModaEdit(true);
        setFormCode(department.code);
        setFormName(department.name);
        setFormDesc(department.description)
    }
  const handleClose = () => {
    setIsDepartmentModalOpen(false);
    setFormCode('');
    setFormName('');
    setFormDesc('');
    setFormNameError(false);
    };

    const handleChildEdit = (departmentId) => {
        setDepartmentEditId(departmentId)
        const department = departments.find(x => x.id === departmentId)
        handleEditModalOpen(department)
    }
  const [formCode, setFormCode] = useState('');
  const [formName, setFormName] = useState('');
  const [formDesc, setFormDesc] = useState('');
  const [typeAlert, setTypeAlert] = useState('');
  const [messageAlert, setMessageAlert] = useState('');
  const [formNameError, setFormNameError] = useState(false);

  const handleCodeChange = (event) => {
    setFormCode(event.target.value);
    };

  const handleNameChange = (event) => {
    setFormName(event.target.value);
    };

  const handleDescChange = (event) => {
    setFormDesc(event.target.value);
    };

    const handleEdit = () => {
        onEdit(plantId)
    }

  const handleDepartmentSave = async () => {
    const department =  new Department();
    const plant = new Plant();
    plant.id = plantId;
    department.plant = plant;
    department.name = formName;
    department.code = formCode;
    department.description = formDesc;
      if (auth) {
          department.createdBy = auth.user?.id;
      }
    let isNameError;

    if(!formName.trim()) {
      setFormNameError(true);
      isNameError = true;
    } else {
      setFormNameError(false);
      isNameError = false;
    }

    if(isNameError) {
      return;
    }

    fetch(ApiUrl.departmentEndpoint, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(department),
    })
    .then(() => {
      handleClose();
      getData();
      setOpen(true);
      setTypeAlert("success");
      setMessageAlert("Department added successfully");
    })
    .catch(error => {
      console.error(error);
      setOpen(true);
      setTypeAlert("error");
      setMessageAlert("Error adding Department");
    });
  }

  const handleDepartmentUpdate = async () => {
    const department =  new Department();
    const plant = new Plant();
    plant.id = plantId;
    department.id = departmentEditId;
    department.plant = plant;
    department.name = formName;
    department.code = formCode;
    department.description = formDesc;
      if (auth) {
          department.modifiedBy = auth.user?.id;
      }
    let isNameError;

    if(!formName.trim()) {
      setFormNameError(true);
      isNameError = true;
    } else {
      setFormNameError(false);
      isNameError = false;
    }

    if(isNameError) {
      return;
    }

    fetch(ApiUrl.departmentEndpoint, {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(department),
    })
    .then(() => {
      handleClose();
      getData();
      setOpen(true);
      setTypeAlert("success");
      setMessageAlert("Department updated successfully");
    })
    .catch(error => {
      console.error(error);
      setOpen(true);
      setTypeAlert("error");
      setMessageAlert("Error updating department");
    });
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  };

  function getData() {
    fetch(`${ApiUrl.departmentEndpoint}/GetAllByPlant/${plantId}`)
      .then(response => response.json())
      .then(json => setData(json))
      .catch(error => console.error(error));
  }
  
  const handleClick = async (nodeId) => {
    if (!isApiCalled) {
      getData();
    }

    const currentIndex = expanded.indexOf(nodeId);
    const newExpanded = [...expanded];

    if (currentIndex === -1) {
      newExpanded.push(nodeId);
    } else {
      newExpanded.splice(currentIndex, 1);
    }

    setExpanded(newExpanded);
  };

  const handleCloseSnackbar = (reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setOpen(false);
};

  function setData(json) {
    const departments = json.map(item => Department.fromJSON(item));
    setIsApiCalled(true);
    setDepartments(departments);
    setExpanded((prevExpanded) => [...prevExpanded, String(nodeId)]);
  }

  const itemLabel = (
    <Box display="flex" alignItems="center" style={{ padding: '15px' }}>
      <Box display="flex" alignItems="center" flexGrow={1}>
        <FactoryIcon />
        <Typography variant="body1" style={{ marginLeft: 10 }}>{label}</Typography>
      </Box>
      <Tooltip placement="bottom" title="Add a new Department">
        <AddIcon onClick={handleOpen} style={{ marginRight: "15px" }} />
      </Tooltip>
      <Tooltip placement="bottom" title="Edit this Plant">
        <EditIcon onClick={handleEdit}  style={{ marginRight: "15px" }} />
      </Tooltip>
    </Box>
  );

  return (
    <Box>
       <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={4000} onClose={handleCloseSnackbar}>
            <Alert severity={typeAlert}>
                {messageAlert}
            </Alert>
        </Snackbar>
      <BaseTreeItem nodeId={nodeId} label={itemLabel} onClick={() => handleClick(nodeId)}>
              {departments.map(department => (
                  <DepartmentItem onEdit={handleChildEdit} key={`1${department.id}`} nodeId={`1${department.id}`} departmentId={department.id} label={`${department.name}${department.code ? ` (${department.code})` : '' }`} />
          ))}
      </BaseTreeItem>
      <Modal
        open={isDepartmentModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" component="h2">{isDepartmentModaEdit ? "Edit Department" : " Add a new Department"}</Typography>
          <form>
            <Box display="flex" alignItems="center" style={{ marginBottom: 8 }}>
              <TextField
                error={formNameError}
                helperText={formNameError ? "Name is required" : ""}
                label="Name"
                variant="outlined"
                margin="normal"
                fullWidth
                style={{ marginRight: 15 }}
                value={formName}
                onChange={handleNameChange}
              />
            </Box>
            <Box display="flex" alignItems="center" style={{ marginBottom: 8 }}>
                <TextField
                  label="Code"
                  variant="outlined"
                  fullWidth
                  style={{ marginRight: 15 }}
                  value={formCode}
                  onChange={handleCodeChange}
                />
                </Box>
                <Box display="flex" alignItems="center" style={{ marginTop: 16 }}>
                <TextareaAutosize
                    placeholder="Description"
                    variant="outlined"
                    style={{
                        width: '100%',
                        marginRight: 15,
                        minHeight: '56px',
                        padding: '8px',
                        border: '1px solid #ced4da',
                        borderRadius: '4px',
                        resize: 'none',
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                    }}
                    value={formDesc}
                    onChange={handleDescChange}
                />
                </Box>
                <Box marginTop={3} display="flex" alignItems="center">
                    {isDepartmentModaEdit ?
                        <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleDepartmentUpdate}>
                            <SaveIcon style={{ marginRight: '15px' }} />Update
                        </Button> :
                        <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleDepartmentSave}>
                            <SaveIcon style={{ marginRight: '15px' }} />Save
                        </Button>
                    }
              <Button variant="contained" color="error" disableElevation onClick={handleClose}>Close</Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default PlantItem;
