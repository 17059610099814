import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, Button, Paper, Table, TableBody, TableCell, TableContainer, Alert, TableHead, TableRow,FormControl, Select, TextField, MenuItem, Modal, Checkbox, ListItemText, styled } from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RestoreIcon from '@mui/icons-material/Restore';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import Snackbar from '@mui/material/Snackbar';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import InputLabel from '@mui/material/InputLabel';
import { ApiUrl } from '../../ApiUrl';
import { User } from '../../Model/User';
import { useParams } from "react-router";
import { CheckList } from '../../Model/CheckList';
import { CheckListIndicator } from '../../Model/CheckListIndicator';
import { CheckListStatus } from '../../Model/Enum/CheckListStatus';
import { CheckListType } from '../../Model/Enum/CheckListType';
import appColor from '../../Style/appColor';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import { useAuth } from "../../Hooks/AuthProvider";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { AuditCheckListDto } from '../../Model/Dto/AuditCheckListDto';
function CustomToolbar() {
    return (
        <GridToolbarContainer>
        </GridToolbarContainer>
    );
}

const CreateAuditSchema = Yup.object().shape({
    typeId: Yup.string().required('Type is required'),
    title: Yup.string().required('Title is required'),
    subjectId: Yup.string().required('Subject is required'),
    startDate: Yup.string().required('StartDate is required'),
    endDate: Yup.string().required('EndDate is required'),
    plantId: Yup.string().required('Plant is required'),
    departmentId: Yup.string().required('Department is required'),
    areaIds: Yup.array().min(1, 'Area is required').required('Area is required'),
    leadAuditor: Yup.string().required('LeadAuditor is required'),
    hours: Yup.number().min(1, 'Hours must be > 0').nullable(true),
})
function CreateAuditPage({ audit }) {
    const isInitialLoad = useRef(true);
    const navigate = useNavigate();
    const auth = useAuth();
    const navigateBack = () => navigate('/planning-programming');

    const [checkLists, setCheckLists] = useState([]);
    const [checkListIndicators, setCheckListIndicators] = useState([]);
    const [indicators, setIndicators] = useState([]);

    const [plants, setPlants] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [areas, setAreas] = useState([]);
    const [lines, setLines] = useState([]);
    const [machines, setMachines] = useState([]);

    const [users, setUsers] = useState([]);

    const [formSubjectList, setFormSubjectList] = useState([]);

 
    const [checkListSelectedDesc, setCheckListSelectedDesc] = useState("");
    const [checkListSelectedId, setCheckListSelectedId] = useState(0);
    const [typeAlert, setTypeAlert] = useState("");
    const [messageAlert, setMessageAlert] = useState("");

    const [formSelectedArea, setFormSelectedArea] = useState([]);
    const [formSelectedLine, setFormSelectedLine] = useState([]);
    const [formSelectedMachine, setFormSelectedMachine] = useState([]);

    const [formCheckListsAdded, setFormCheckListsAdded] = useState([]);
    const [machineTemp, setMachineTemp] = useState([]);
    const [lineTemp, setLineTemp] = useState([]);

    //const [formCheckListIndicatorsRemoved, setFormCheckListIndicatorsRemoved] = useState([]);

    //const [formNoteIndicatorError, setFormNoteIndicatorError] = useState({});
    const [formCheckListIndicatorsRemoved, setFormCheckListIndicatorsRemoved] = useState([]);
    const [localChangesCache, setLocalChangesCache] = useState({}); 
    //const [noteErrors, setNoteErrors] = useState({});
    const [removedIndicators, setRemovedIndicators] = useState({});
    const [noteErrors, setNoteErrors] = useState({});
    const [isCheckListModalOpen, setIsCheckListModalOpen] = useState(false);
    const [isCheckListEditModalOpen, setIsCheckListEditModalOpen] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [open, setOpen] = useState(false);
    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
    const handleWarningModalClose = () => setIsWarningModalOpen(false);
    const handleCheckListModalOpen = () => { setIsCheckListModalOpen(true);  }
    const handleCheckListEditModalClose = (id) => {
        if (id) {
            const tempCheckListIndicatorRemoved = formCheckListIndicatorsRemoved.filter(checkListIndicatorAdded => checkListIndicatorAdded.checkListId !== id);
            setFormCheckListIndicatorsRemoved(tempCheckListIndicatorRemoved);
        }
        isInitialLoad.current = true; 
        setIsCheckListEditModalOpen(false);
    };
    const handleCheckListModalClose = () => setIsCheckListModalOpen(false);


    const [initialValues, setInitialValues] = useState({
        id: 0,  
        typeId: '',
        title: '',
        note: '',
        subjectId: '',
        startDate: null,
        endDate: null,
        plantId: '',
        areaIds: [],
        hours: null,
        departmentId: '',
        leadAuditor: '',
        auditor2: 0,
        auditor3: 0,
        auditor4: 0,
        auditor5: 0,
        checklistIds: [],
        lineIds: [],
        machineIds: []
    });

    useEffect(() => {
        getPlantList();
        getUsersList();
        getIndicators()
        getAuditSubjectList();
        getCheckListList();

        if (audit) {
            getCheckListsByAudit()
            console.log("Audit", audit);
            console.log("UserId", audit.userIds[0]);
            if (audit.plantId) {
                getDepartmentsByPlant(audit.plantId)
                if (audit.departmentId) {
                    getAreasByDepartment(audit.departmentId)
                    if (audit.areaIds) {
                        audit.areaIds.map((id) => {
                            getLinesByArea(id)
                        })
                        setFormSelectedArea(audit.areaIds)
                        if (audit.lineIds) {
                            audit.lineIds.map((id) => {
                                getMachinesByLine(id)
                            })
                            setFormSelectedLine(audit.lineIds)
                            if (audit.machineIds) {
                                setFormSelectedMachine(audit.machineIds)
                            }
                        }
                    }
                }
            }
            setInitialValues({
                id: audit.id || 0,
                typeId: audit.typeId || '',
                title: audit.title || '',
                note: audit.note || '',
                subjectId: audit.subjectId || '',
                startDate: dayjs(audit.startDate) || null,
                endDate: dayjs(audit.endDate) || null,
                plantId: audit.plantId || '',
                areaIds: audit.areaIds || [],
                hours: audit.hours || null,
                departmentId: audit.departmentId || '',
                leadAuditor: audit.userIds ? audit.userIds[0] : 0,
                auditor2: audit.userIds ? audit.userIds[1] ? audit.userIds[1] : 0 : 0,
                auditor3: audit.userIds ? audit.userIds[2] ? audit.userIds[2] : 0 : 0,
                auditor4: audit.userIds ? audit.userIds[3] ? audit.userIds[3] : 0 : 0,
                auditor5: audit.userIds ? audit.userIds[4] ? audit.userIds[4] : 0 : 0,
                checklistIds: audit.checklistIds || [],
                lineIds: audit.lineIds || [],
                machineIds: audit.machineIds || []
            });

        }
    }, [audit]);

    useEffect(() => {
        if (audit && checkListSelectedId !== 0) {
            const cachedData = localChangesCache[checkListSelectedId];

            if (cachedData) {
                setRemovedIndicators(cachedData.removedIndicators);
                setFormCheckListIndicatorsRemoved(cachedData.formCheckListIndicatorsRemoved);
            } else {
                fetch(`${ApiUrl.auditEndpoint}/AuditJsonIndicators/${audit.id}/${checkListSelectedId}`)
                    .then(response => response.json())
                    .then((json) => {
                        const initialRemoved = {};
                        const initialFormRemoved = [];

                        Object.entries(json).forEach(([id, details]) => {
                            if (!details.Enabled) {
                                initialRemoved[id] = { note: details.Note || "" };
                                initialFormRemoved.push({
                                    checkListId: checkListSelectedId,
                                    id: parseInt(id),
                                    note: details.Note || ""
                                });
                            }
                        });

                        setRemovedIndicators(initialRemoved);
                        setFormCheckListIndicatorsRemoved(initialFormRemoved);

                        // Cache fetched data for this checklist
                        setLocalChangesCache(prev => ({
                            ...prev,
                            [checkListSelectedId]: {
                                removedIndicators: { ...initialRemoved },
                                formCheckListIndicatorsRemoved: [...initialFormRemoved]
                            }
                        }));
                    })
                    .catch(error => console.error(error));
            }
        }
    }, [audit, checkListSelectedId]);

    function getCheckListsByAudit() {
        fetch(ApiUrl.auditEndpoint + "/CheckListAudit/" + audit.id)
            .then(response => response.json())
            .then(json => {
                const tempInPromises = json.map(async checkListId => {
                    const response = await fetch(ApiUrl.checkListEndpoint + "/" + checkListId);
                    return await response.json();
                });
                return Promise.all(tempInPromises);
            })
            .then(allCheckList => {
                console.log('allchecklist', allCheckList)
                const newFormCheckListsAdded = [];
                allCheckList.forEach(checkList => {
                    const addCheckList = new CheckList();
                    addCheckList.id = checkList.id;
                    addCheckList.code = checkList.code;
                    addCheckList.type = checkList.type;
                    addCheckList.subject = checkList.subject;
                    addCheckList.description = checkList.description;
                    addCheckList.created = checkList.created;

                    const profile = new User();
                    profile.name = checkList.ownerUser.name;
                    profile.surname = checkList.ownerUser.surname;
                    addCheckList.ownerUser = profile;

                    newFormCheckListsAdded.push(addCheckList)

                });
                setFormCheckListsAdded(newFormCheckListsAdded)
            })
            .catch(error => console.error(error));
    }
    const handleCloseSnackbar = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    //const handleFormIndicatorNoteChange = (event, id) => {
    //    setNoteIndicators(prevState => ({
    //        ...prevState,
    //        [id]: event.target.value
    //    }));
    //};

    
    const handleNoteChange = (event, id) => {
        const note = event.target.value;
        if (note.length < 3) {
            setNoteErrors(prev => ({ ...prev, [id]: true }));
        } else {
            setNoteErrors(prev => ({ ...prev, [id]: false }));
        }
        const updatedIndicators = {
            ...removedIndicators,
            [id]: { ...removedIndicators[id], note }
        };
        setRemovedIndicators(updatedIndicators);

        const updatedFormRemoved = (() => {
            const updatedList = [...formCheckListIndicatorsRemoved];
            const itemIndex = updatedList.findIndex(item => item.id === id);

            if (itemIndex !== -1) {
                updatedList[itemIndex].note = note;
            } else {
                updatedList.push({
                    checkListId: checkListSelectedId,
                    id,
                    note
                });
            }
            return updatedList;
        })();

        // Set the updated formCheckListIndicatorsRemoved state
        setFormCheckListIndicatorsRemoved(updatedFormRemoved);

        // Cache the updates specifically for this checklist
        setLocalChangesCache(prev => ({
            ...prev,
            [checkListSelectedId]: {
                removedIndicators: { ...updatedIndicators },
                formCheckListIndicatorsRemoved: [...updatedFormRemoved]
            }
        }));
    };


    const handleToggleIndicator = (indicator) => {
        const { id } = indicator;
        const isRemoved = removedIndicators[id];

        if (isRemoved) {
            const updatedFormRemoved = formCheckListIndicatorsRemoved.filter(item => item.id !== id);
            const updatedIndicators = { ...removedIndicators };
            delete updatedIndicators[id];

            setFormCheckListIndicatorsRemoved(updatedFormRemoved);
            setRemovedIndicators(updatedIndicators);

            setLocalChangesCache(prev => ({
                ...prev,
                [checkListSelectedId]: {
                    removedIndicators: updatedIndicators,
                    formCheckListIndicatorsRemoved: updatedFormRemoved
                }
            }));
        }

        // Clear validation error if restored
        setNoteErrors(prev => ({ ...prev, [id]: false }));
    };
    const [selectAllArea, setSelectAllArea] = useState(false);
    const [selectAllLine, setSelectAllLine] = useState(false);
    const [selectAllMachine, setSelectAllMachine] = useState(false);


    const handleCheckListEditModalOpen = (params) => {
        getCheckListIndicatorList(params.id)
        setCheckListSelectedDesc(params.description)
        setCheckListSelectedId(params.id)
        setIsCheckListEditModalOpen(true);
    }

    const handleFormCheckListsAddedAdd = (checkList) => {
        if (formCheckListsAdded.some(checkListAdded => checkListAdded.id === checkList.id)) {
            const tempCheckListAdded = formCheckListsAdded.filter(checkListAdded => checkListAdded.id !== checkList.id);
            setFormCheckListsAdded(tempCheckListAdded);
        } else {
            setFormCheckListsAdded([...formCheckListsAdded, checkList]);
        }
    }

    //const handleCheckListIndicatorRemove = (checkListIndicator, noteIndicator) => {
    //    debugger;
    //    console.log('formCheckListIndicatorsRemoved', formCheckListIndicatorsRemoved)
    //    let isFormNoteIndicatorError = false;
    //    if (noteIndicator === undefined || noteIndicator === null || noteIndicator === "" || noteIndicator.lenght < 2) {
    //        setFormNoteIndicatorError(prevState => ({
    //            ...prevState,
    //            [checkListIndicator.id]: true
    //        }));
    //        isFormNoteIndicatorError = true;
    //    }
    //    else {
    //        setFormNoteIndicatorError(prevState => ({
    //            ...prevState,
    //            [checkListIndicator.id]: false
    //        }));
    //    }
    //    if (isFormNoteIndicatorError) {
    //        return;
    //    }

    //    if (formCheckListIndicatorsRemoved.some(checkListIndicatorAdded => checkListIndicatorAdded.id === checkListIndicator.id)) {
    //        const tempCheckListIndicatorRemoved = formCheckListIndicatorsRemoved.filter(checkListIndicatorAdded => checkListIndicatorAdded.id !== checkListIndicator.id);
    //        setFormCheckListIndicatorsRemoved(tempCheckListIndicatorRemoved);
    //        setNoteIndicators(prevState => ({
    //            ...prevState,
    //            [checkListIndicator.id]: null
    //        }));
    //    }
    //    else {
    //        const addCheckListIndicator = new CheckListIndicator();
    //        addCheckListIndicator.id = checkListIndicator.id;
    //        addCheckListIndicator.checkListId = checkListIndicator.checkListId;
    //        addCheckListIndicator.item = checkListIndicator.item;
    //        addCheckListIndicator.description = checkListIndicator.description;
    //        addCheckListIndicator.weight = checkListIndicator.weight;
    //        addCheckListIndicator.numberOfRatings = checkListIndicator.numberOfRatings;
    //        addCheckListIndicator.note = noteIndicator;

    //        setFormCheckListIndicatorsRemoved([...formCheckListIndicatorsRemoved, addCheckListIndicator])
    //        setNoteIndicators(prevState => ({
    //            ...prevState,
    //            [checkListIndicator.id]: noteIndicator
    //        }));
    //    }
    //}

    const handleFormCheckListsAddedDelete = (checkList) => {
        const tempFormCheckListsAdded = formCheckListsAdded.filter(checkListAdded => checkListAdded.id !== checkList.id);
        setFormCheckListsAdded(tempFormCheckListsAdded);
        fetch(`${ApiUrl.auditEndpoint}/AuditCheckList/${audit.id}/${checkList.id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(() => {
            setFormCheckListsAdded(tempFormCheckListsAdded);
        })
        .catch(error => {
            console.log(error);
        });
    }

    const getAuditSubjectList = () => {
        fetch(ApiUrl.subjectEndpoint + "/Type/1")
            .then(response => response.json())
            .then(json => setFormSubjectList(json))
            .catch(error => console.error(error));
    }
    function getPlantList() {
        fetch(ApiUrl.plantEndpoint)
            .then(response => response.json())
            .then(json => setPlants(json))
            .catch(error => console.error(error));
    }
    function getDepartmentsByPlant(plantId) {
        fetch(`${ApiUrl.departmentEndpoint}/GetAllByPlant/${plantId}`)
            .then(response => response.json())
            .then(json => setDepartments(json))
            .catch(error => console.error(error));
    }
    function getAreasByDepartment(departmentId) {
        fetch(`${ApiUrl.areaEndpoint}/GetAllByDepartment/${departmentId}`)
            .then(response => response.json())
            .then(json => setAreas(json))
            .catch(error => console.error(error));
    }
    function getLinesByArea(areaId) {
        setLineTemp([]);
        fetch(`${ApiUrl.lineEndpoint}/GetAllByArea/${areaId}`)
            .then(response => response.json())
            .then(json => {
                json.forEach(item => {
                    lineTemp.push(item)
                })
                setLines([])
                setLines(lineTemp)
            })
            .catch(error => console.error(error));
    }

    function getMachinesByLine(lineId) {
        setMachineTemp([]);
        fetch(`${ApiUrl.machineEndpoint}/GetAllByLine/${lineId}`)
            .then(response => response.json())
            .then(json => {
                json.forEach(item => {
                    machineTemp.push(item)
                })
                setMachines([]);
                setMachines(machineTemp);
            })
            .catch(error => console.error(error));
    }

    function getUsersList() {
        fetch(ApiUrl.userEndpoint)
            .then(response => response.json())
            .then(json => setUsers(json))
            .catch(error => console.error(error));
    }

    function getCheckListList() {
        fetch(ApiUrl.checkListEndpoint)
            .then(response => response.json())
            .then(json => setCheckLists(json))
            .catch(error => console.error(error));
    }
    function getIndicators() {
        fetch(ApiUrl.checkListIndicatorEndpoint)
            .then(response => response.json())
            .then(json => setIndicators(json))
            .catch(error => console.error(error));
    }
    function getCheckListIndicatorList(checkListId) {
        fetch(`${ApiUrl.checkListIndicatorEndpoint}/GetAllByCheckList/${checkListId}`)
            .then(response => response.json())
            .then((json) => {
                console.log('indicators', json)
                setCheckListIndicators(json)
            })
            .catch(error => console.error(error));
    }


    const handleAuditSave = (audit) => {
        //console.log('submit audit', audit)
        //return;
        setLoadingButton(true);
        if (formCheckListsAdded.length === 0) {
            setIsWarningModalOpen(true);
            setLoadingButton(false);
            return;
        }
        console.log('formCheckListsAdded', formCheckListsAdded)
        console.log('formCheckListIndicatorsRemoved', formCheckListIndicatorsRemoved)
        try {
            fetch(ApiUrl.auditEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(audit),
            })
            .then(response => response.json())
            .then(data => audit.id = data)
                .then(() => {
                formCheckListsAdded.forEach(checkList => {
                    debugger;
                    const auditCheckList = new AuditCheckListDto();
                    auditCheckList.auditId = audit.id;
                    auditCheckList.checkListId = checkList.id;
                    const checkListIndicatorsTemp = indicators.filter(ind => ind.checkListId === checkList.id);
                    let jsonIndicators = {

                    };
                    let formCheckListIndicatorsRemoved2 = [];

                    Object.entries(localChangesCache).forEach(([key, value]) => {
                        debugger;
                        if (parseInt(key) === checkList.id) {
                            formCheckListIndicatorsRemoved2 = value.formCheckListIndicatorsRemoved;
                        }
                    });
                    checkListIndicatorsTemp.forEach(ind => {
                        const matchingItem = formCheckListIndicatorsRemoved2.find(item =>
                            item.checkListId === checkList.id && item.id === ind.id
                        );
                        let jsonIndicator = {};
                        jsonIndicator[ind.id] = {
                            "Enabled": matchingItem ? false : true,
                            "Note": matchingItem ? matchingItem.note : ""
                        }
                        jsonIndicators[ind.id] = jsonIndicator[ind.id];
                    });
                    auditCheckList.indicators = jsonIndicators;

                    fetch(ApiUrl.auditEndpoint + "/AuditCheckList", {
                        method: audit.id > 0 ? 'PUT' : 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(auditCheckList),
                    })
                        .catch(error => {
                            console.log("Error during post method for Checklist", error);
                            setOpen(true);
                            setTypeAlert("error");
                            setMessageAlert("Error during create Chekclist");
                        });
                })
            })
                .then(() => {
                    setOpen(true);
                    setTypeAlert("success");
                    setMessageAlert("Action success! Wait to go back");

                    setLoadingButton(false);

                    setTimeout(() => {
                        navigateBack();
                    }, 2000);
                })
            .catch(error => {
                console.log("Error during post method for audit", error);
                setOpen(true);
                setTypeAlert("error");
                setMessageAlert("Error during create auditor 1");
                setLoadingButton(false);
            });
        } catch (error) {
            console.log("error", error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error creating audit");
        }        
    }

    const columns = [
        {
            width: 75,
            headerName: '',
            field: '',
            renderCell: (params) => (
                <div>
                    <Checkbox
                        checked={formCheckListsAdded.some((checkList) => params.row.id === checkList.id)}
                        key={params.row.id}
                        onChange={() => handleFormCheckListsAddedAdd(params.row)}
                    />
                    {/*<Checkbox checked={formCheckListsAdded.filter((checkList) => params.row.id === checkList.id).length > 0} key={params.row.id} onChange={() => handleFormCheckListsAddedAdd(params.row)} />*/}
                </div>
            )
        },
        {
            field: 'id',
            headerName: 'Id',
            hidden:true,
            width: 100
        },
        {
            field: 'code',
            headerName: 'Code',
            width: 200
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 100,
            hidden: true,
            renderCell: (params) => CheckListStatus[params.value]
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 100,
            renderCell: (params) => CheckListType[params.value]
        },
        {
            field: 'subject',
            headerName: 'Subject',
            width: 100,
            valueGetter: (params) => params.row.subject.name
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 300
        },
        {
            field: 'ownerUser',
            headerName: 'Owner',
            width: 150,
            valueGetter: (params) => params.row.ownerUser.name + " " + params.row.ownerUser.surname
        }
    ];

    const auditCheckListColumns = [
        {
            field: 'id',
            headerName: 'Id',
            hidden: true,
            width: 100
        },
        {
            field: 'code',
            headerName: 'Code',
            width: 200
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 100,
            renderCell: (params) => CheckListType[params.value]
        },
        {
            field: 'subject',
            headerName: 'Subject',
            width: 150,
            valueGetter: (params) => params.row.subject.name
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 400
        },
        {
            field: 'ownerUser',
            headerName: 'Owner',
            width: 200,
            valueGetter: (params) => params.row.ownerUser.name + " " + params.row.ownerUser.surname
        },
        {
            field: 'created',
            headerName: 'Date',
            width: 200,
            valueGetter: (params) => moment(params.row.dateValue).format('MM-DD-YYYY')
        },
        {
            field: 'action',
            headerName: 'Actions',
            width: 100,
            renderCell: (params) => (
                <div>
                    <EditIcon style={{ cursor: 'pointer' }} onClick={() => handleCheckListEditModalOpen(params.row)} />
                    <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => handleFormCheckListsAddedDelete(params.row)} />
                </div>
            )
        }
    ];

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Box>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={4000} onClose={handleCloseSnackbar}>
                <Alert severity={typeAlert}>
                    {messageAlert}
                </Alert>
            </Snackbar>
            <Box display="flex" alignItems="center" style={{ marginBottom: 20 }}>
                <Button variant="contained" disableElevation onClick={navigateBack}>
                    <ArrowBackIcon />
                </Button>
                <Typography variant="h4" style={{ marginLeft: 30 }}>{audit ? `Edit Audit ${audit.id} - ${audit.title}` : "Create new Audit"}</Typography>
            </Box>
            <Paper elevation={0} style={{ padding: "15px", border: "1px solid lightgray" }}>
                <div>
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={CreateAuditSchema}
                        onSubmit={values => {
                            values.checklist = formCheckListsAdded.map(checklist => checklist.id);
                            handleAuditSave(values)
                        }}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
                            <Form>                                
                                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                    <FormControl fullWidth style={{ marginBottom: '16px' }}>
                                        <InputLabel id="type-label">Type*</InputLabel>
                                        <Select
                                            labelId="type-label"
                                            id="type-select"
                                            label="Type*"
                                            name="typeId"
                                            error={!!errors.typeId && !!touched.typeId}
                                            value={values.typeId || ''}
                                            onChange={(e, selectedValue) => setFieldValue("typeId", selectedValue.props.value)}
                                            onBlur={handleBlur}
                                        >
                                            <MenuItem key={0} value=''></MenuItem>
                                            <MenuItem key={1} value="1">A</MenuItem>
                                            <MenuItem key={2} value="2">B</MenuItem>
                                            <MenuItem key={3} value="3">C</MenuItem>
                                        </Select>
                                        {errors.typeId && touched.typeId ? (
                                            <div style={{ width: '100%', fontSize: '0.8em', color: "red" }}>{errors.typeId}</div>
                                        ) : null}
                                    </FormControl>

                                    <FormControl fullWidth style={{ marginBottom: '16px' }}>
                                        <TextField
                                            label="Title*"
                                            variant="outlined"
                                            name="title"
                                            error={!!errors.title && !!touched.title}
                                            value={values.title}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.title && touched.title ? (
                                            <div style={{ width: '100%', fontSize: '0.8em', color: "red" }}>{errors.title}</div>
                                        ) : null}
                                    </FormControl>

                                    <FormControl fullWidth style={{ marginBottom: '16px' }}>
                                        <InputLabel id="subject-label">Subject*</InputLabel>
                                        <Select
                                            labelId="subject-label"
                                            label="Subject*"
                                            name="subjectId"
                                            error={!!errors.subjectId && !!touched.subjectId}
                                            value={values.subjectId || ''}
                                            onChange={(e, selectedValue) => setFieldValue("subjectId", selectedValue.props.value)}
                                            onBlur={handleBlur}
                                        >
                                            <MenuItem key={0} value=''></MenuItem>
                                            {formSubjectList.map((formSubject) => <MenuItem key={formSubject.id} value={formSubject.id}>{formSubject.name}</MenuItem>)}
                                        </Select>
                                        {errors.subjectId && touched.subjectId ? (
                                            <div style={{ width: '100%', fontSize: '0.8em', color: "red" }}>{errors.subjectId}</div>
                                        ) : null}
                                    </FormControl>

                                    <FormControl fullWidth style={{ marginBottom: '16px' }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                                                {values.startDate && values.endDate && dayjs(values.startDate).isSame(values.endDate) && (
                                                    <TextField
                                                        label="Hours"
                                                        variant="outlined"
                                                        type="number"
                                                        error={!!errors.hours && !!touched.hours}
                                                        value={values.hours}
                                                        onChange={(e) => setFieldValue("hours", e.target.value)}
                                                    />
                                                )}
                                                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', width: '45%' }}>
                                                        <DatePicker
                                                            label="Start Date*"
                                                            sx={{
                                                                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: (!!errors.startDate && !!touched.startDate) ? '1px solid red' : '' },
                                                            }}
                                                            value={values.startDate}
                                                            onChange={value => setFieldValue('startDate', value)}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.startDate && touched.startDate && (
                                                            <div style={{ width: '100%', fontSize: '0.8em', color: "red" }}>{errors.startDate}</div>
                                                        )}
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', width: '45%' }}>
                                                        <DatePicker
                                                            label="End Date*"
                                                            sx={{
                                                                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: (!!errors.endDate && !!touched.endDate) ? '1px solid red' : '' },
                                                            }}
                                                            value={values.endDate}
                                                            onChange={value => setFieldValue('endDate', value)}
                                                        />
                                                        {errors.endDate && touched.endDate && (
                                                            <div style={{ width: '100%', fontSize: '0.8em', color: "red" }}>{errors.endDate}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </LocalizationProvider>
                                        {errors.hours && touched.hours && (
                                            <div style={{ width: '100%', fontSize: '0.8em', color: "red", marginTop: '8px' }}>{errors.hours}</div>
                                        )}
                                    </FormControl>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                    <FormControl fullWidth style={{ marginBottom: '16px' }}>
                                        <InputLabel id="plant-label">Plant*</InputLabel>
                                        <Select
                                            label="Plant*"
                                            labelId="plant-label"
                                            name="plantId"
                                            error={!!errors.plantId && !!touched.plantId}
                                            value={values.plantId || ''}
                                            onChange={(e, selectedValue) => {
                                                setDepartments([]);
                                                setAreas([]);
                                                setFormSelectedArea([]);
                                                setSelectAllArea(false);
                                                setLines([]);
                                                setFormSelectedLine([]);
                                                setSelectAllLine(false);
                                                setMachines([]);
                                                setFormSelectedMachine([]);
                                                setSelectAllMachine(false);
                                                setFieldValue("departmentId", '');
                                                setFieldValue("areaIds", []);
                                                setFieldValue("lineIds", []);
                                                setFieldValue("machineIds", []);
                                                setFieldValue("plantId", selectedValue.props.value)
                                                getDepartmentsByPlant(selectedValue.props.value)
                                            }
                                            }
                                            onBlur={handleBlur}
                                        >
                                            <MenuItem key={0} value=''></MenuItem>
                                            {plants.map((plant) => <MenuItem key={plant.id} value={plant.id}>{plant.code}</MenuItem>)}
                                        </Select>
                                        {errors.plantId && touched.plantId ? (
                                            <div style={{ width: '100%', fontSize: '0.8em', color: "red" }}>{errors.plantId}</div>
                                        ) : null}
                                    </FormControl>

                                    <FormControl fullWidth style={{ marginBottom: '16px' }}>
                                        <InputLabel id="department-label">Department*</InputLabel>
                                        <Select
                                            label="Department*"
                                            labelId="department-label"
                                            name="departmentId"
                                            error={!!errors.departmentId && !!touched.departmentId}
                                            value={values.departmentId || ''}
                                            onChange={(e, selectedValue) => {
                                                setAreas([]);
                                                setFormSelectedArea([]);
                                                setSelectAllArea(false);
                                                setLines([]);
                                                setFormSelectedLine([]);
                                                setSelectAllLine(false);
                                                setMachines([]);
                                                setFormSelectedMachine([]);
                                                setSelectAllMachine(false);
                                                setFieldValue("areaIds", []);
                                                setFieldValue("lineIds", []);
                                                setFieldValue("machineIds", []);
                                                setFieldValue("departmentId", selectedValue.props.value)
                                                getAreasByDepartment(selectedValue.props.value)
                                            }
                                            }
                                            onBlur={handleBlur}
                                        >
                                            <MenuItem key={0} value=''></MenuItem>
                                            {departments.map((department) => <MenuItem key={department.id} value={department.id}>{department.name}</MenuItem>)}
                                        </Select>
                                        {errors.departmentId && touched.departmentId ? (
                                            <div style={{ width: '100%', fontSize: '0.8em', color: "red" }}>{errors.departmentId}</div>
                                        ) : null}
                                    </FormControl>

                                    <FormControl fullWidth style={{ marginBottom: '16px' }}>
                                        <InputLabel id="area-label">Area*</InputLabel>
                                        <Select
                                            label="Area*"
                                            labelId="area-label"
                                            name="areaIds"
                                            multiple
                                            error={!!errors.areaIds && !!touched.areaIds}
                                            value={values.areaIds || []}
                                            onChange={(e) => {
                                                setLines([]);
                                                setFormSelectedLine([]);
                                                setSelectAllLine(false);
                                                setMachines([]);
                                                setFormSelectedMachine([]);
                                                setSelectAllMachine(false);
                                                setFieldValue("lineIds", []);
                                                setFieldValue("machineIds", []);
                                                const { target: { value }, } = e;
                                                if (value.includes("selectAllArea")) {
                                                    if (selectAllArea) {
                                                        setFieldValue("areaIds", []);
                                                        setFormSelectedArea([]);
                                                        setSelectAllArea(false);
                                                    } else {
                                                        const allAreas = areas.map(a => a.id);
                                                        setFieldValue("areaIds", allAreas);
                                                        setFormSelectedArea(allAreas);
                                                        setSelectAllArea(true);
                                                        allAreas.forEach((a) => {
                                                            getLinesByArea(a);
                                                        })

                                                    }
                                                } else {
                                                    setFieldValue("areaIds", typeof value === 'string' ? value.split(',') : value);

                                                    value.forEach((v) => {
                                                        getLinesByArea(v);
                                                    })

                                                    setFormSelectedArea(value);
                                                    setSelectAllArea(false);
                                                }
                                            }}
                                            renderValue={(selected) => `${selected.length} selected`}
                                            onBlur={handleBlur}
                                        >

                                            {areas.length > 1 && (
                                                <MenuItem key="selectAllArea" value="selectAllArea">
                                                    <Checkbox checked={selectAllArea} />
                                                    <ListItemText primary="Select All" />
                                                </MenuItem>
                                            )}

                                            {areas.map((area) => <MenuItem key={area.id} value={area.id}>
                                                <Checkbox checked={formSelectedArea.indexOf(area.id) > -1} />
                                                <ListItemText primary={area.name} />
                                            </MenuItem>)}
                                        </Select>
                                        {errors.areaIds && touched.areaIds ? (
                                            <div style={{ width: '100%', fontSize: '0.8em', color: "red" }}>{errors.areaIds}</div>
                                        ) : null}
                                    </FormControl>
                                    
                                    <FormControl fullWidth style={{ marginBottom: '16px' }}>
                                        <InputLabel id="line-label">Line</InputLabel>
                                        <Select
                                            label="Line"
                                            labelId="line-label"
                                            name="lineIds"
                                            multiple
                                            value={values.lineIds || []}
                                            onChange={(e) => {
                                                setMachines([]);
                                                setFormSelectedMachine([]);
                                                setSelectAllMachine(false);
                                                setFieldValue("machineId", []);
                                                const { target: { value }, } = e;
                                                if (value.includes("selectAllLine")) {
                                                    if (selectAllLine) {
                                                        setFieldValue("lineIds", []);
                                                        setFormSelectedLine([]);
                                                        setSelectAllLine(false);
                                                    } else {
                                                        const allLines = lines.map(l => l.id);
                                                        setFieldValue("lineIds", allLines);
                                                        setFormSelectedLine(allLines);
                                                        setSelectAllLine(true);
                                                        allLines.forEach((l) => {
                                                            getMachinesByLine(l)
                                                        })
                                                    }
                                                } else {
                                                    setFieldValue("lineIds", typeof value === 'string' ? value.split(',') : value);

                                                    value.forEach((v) => {
                                                        getMachinesByLine(v);
                                                    })

                                                    setFormSelectedLine(value);
                                                    setSelectAllLine(false);
                                                }
                                            }}
                                            onBlur={handleBlur}
                                            renderValue={(selected) => `${selected.length} selected`}
                                        >
                                            {lines.length > 1 && (
                                                <MenuItem key="selectAllLine" value="selectAllLine">
                                                    <Checkbox checked={selectAllLine} />
                                                    <ListItemText primary="Select All" />
                                                </MenuItem>
                                            )}

                                            {lines.map((line) => <MenuItem key={line.id} value={line.id}>
                                                <Checkbox checked={formSelectedLine.indexOf(line.id) > -1} />
                                                <ListItemText primary={line.name} />
                                            </MenuItem>)}
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth style={{ marginBottom: '16px' }}>
                                        <InputLabel id="machine-label">Machine</InputLabel>
                                        <Select
                                            label="Machine"
                                            labelId="machine-label"
                                            name="machineIds"
                                            multiple
                                            value={values.machineIds || []}
                                            onChange={(e) => {
                                                const { target: { value }, } = e;
                                                if (value.includes("selectAllMachine")) {
                                                    if (selectAllMachine) {
                                                        setFieldValue("machineIds", []);
                                                        setFormSelectedMachine([]);
                                                        setSelectAllMachine(false);
                                                    } else {
                                                        const allMachines = machines.map(m => m.id);
                                                        setFieldValue("machineIds", allMachines);
                                                        setFormSelectedMachine(allMachines);
                                                        setSelectAllMachine(true);
                                                    }
                                                } else {
                                                    setFieldValue("machineIds", typeof value === 'string' ? value.split(',') : value);

                                                    setFormSelectedMachine(value);
                                                    setSelectAllMachine(false);
                                                }
                                            }}
                                            onBlur={handleBlur}
                                            renderValue={(selected) => `${selected.length} selected`}
                                        >
                                            {machines.length > 1 && (
                                                <MenuItem key="selectAllMachine" value="selectAllMachine">
                                                    <Checkbox checked={selectAllMachine} />
                                                    <ListItemText primary="Select All" />
                                                </MenuItem>
                                            )}

                                            {machines.map((machine) => <MenuItem key={machine.id} value={machine.id}>
                                                <Checkbox checked={formSelectedMachine.indexOf(machine.id) > -1} />
                                                <ListItemText primary={machine.name + " " + (machine.code != undefined ? machine.code : "")} />
                                            </MenuItem>)}
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth style={{ marginBottom: '16px' }}>
                                        <TextField
                                            label="Note"
                                            variant="outlined"
                                            name="note"
                                            fullWidth
                                            value={values.note}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </FormControl>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="leadAuditor-label">LeadAuditor*</InputLabel>
                                        <Select
                                            label="LeadAuditor*"
                                            labelId="leadAuditor-label"
                                            name="leadAuditor"
                                            error={!!errors.leadAuditor && !!touched.leadAuditor}
                                            value={values.leadAuditor || ''}
                                            onChange={(e, selectedValue) => setFieldValue("leadAuditor", selectedValue.props.value)}
                                            onBlur={handleBlur}
                                        >
                                            <MenuItem key={0} value=''></MenuItem>
                                            {users.map((user) => <MenuItem key={user.id} value={user.id}>{user.name} {user.surname}</MenuItem>)}
                                        </Select>
                                        {errors.leadAuditor && touched.leadAuditor ? (
                                            <div style={{ width: '100%', fontSize: '0.8em', color: "red" }}>{errors.leadAuditor}</div>
                                        ) : null}
                                    </FormControl>

                                    <FormControl fullWidth>
                                        <InputLabel id="auditor2">Auditor 2</InputLabel>
                                        <Select
                                            label="Auditor 2"
                                            labelId="auditor2"
                                            name="auditor2"
                                            value={values.auditor2 || ''}
                                            onChange={(e, selectedValue) => setFieldValue("auditor2", selectedValue.props.value)}
                                            onBlur={handleBlur}
                                        >
                                            <MenuItem key={0} value=''></MenuItem>
                                            {users.map((user) => <MenuItem key={user.id} value={user.id}>{user.name} {user.surname}</MenuItem>)}
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth>
                                        <InputLabel id="auditor3">Auditor 3</InputLabel>
                                        <Select
                                            label="Auditor 3"
                                            labelId="auditor3"
                                            name="auditor3"
                                            value={values.auditor3 || ''}
                                            onChange={(e, selectedValue) => setFieldValue("auditor3", selectedValue.props.value)}
                                            onBlur={handleBlur}
                                        >
                                            <MenuItem key={0} value=''></MenuItem>
                                            {users.map((user) => <MenuItem key={user.id} value={user.id}>{user.name} {user.surname}</MenuItem>)}
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth>
                                        <InputLabel id="auditor4">Auditor 4</InputLabel>
                                        <Select
                                            label="Auditor 4"
                                            labelId="auditor4"
                                            name="auditor4"
                                            value={values.auditor4 || ''}
                                            onChange={(e, selectedValue) => setFieldValue("auditor4", selectedValue.props.value)}
                                            onBlur={handleBlur}
                                        >
                                            <MenuItem key={0} value=''></MenuItem>
                                            {users.map((user) => <MenuItem key={user.id} value={user.id}>{user.name} {user.surname}</MenuItem>)}
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth>
                                        <InputLabel id="auditor5">Auditor 5</InputLabel>
                                        <Select
                                            label="Auditor 5"
                                            labelId="auditor5"
                                            name="auditor5"
                                            value={values.auditor5 || ''}
                                            onChange={(e, selectedValue) => setFieldValue("auditor5", selectedValue.props.value)}
                                            onBlur={handleBlur}
                                        >
                                            <MenuItem key={0} value=''></MenuItem>
                                            {users.map((user) => <MenuItem key={user.id} value={user.id}>{user.name} {user.surname}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </div>
                                <FormControl fullWidth>
                                    <Box display="flex" alignItems="center" style={{ marginTop: 40, marginBottom: 20 }}>
                                        <Typography variant="h6" style={{ marginRight: 20 }}>CheckList Audit</Typography>
                                        <Button variant="contained" size="small" disableElevation onClick={handleCheckListModalOpen}>
                                            <AddIcon style={{ marginRight: '10px' }} />Add CheckLists
                                        </Button>
                                    </Box>
                                    <DataGrid
                                        style={{ backgroundColor: appColor.white }}
                                        sx={{
                                            '& .css-qvtrhg-MuiDataGrid-virtualScroller': {
                                                overflow: 'unset',
                                                minHeight: '50px'
                                            }
                                        }}
                                        rows={formCheckListsAdded}
                                        columns={auditCheckListColumns.filter(x => !x.hidden)}
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 10,
                                                },
                                            },
                                        }}
                                        slots={{
                                            toolbar: CustomToolbar,
                                        }}
                                        pageSizeOptions={[10]}
                                        disableColumnFilter
                                        disableColumnSelector
                                        disableDensitySelector
                                        disableRowSelectionOnClick
                                        fullWidth
                                    />
                                </FormControl>

                                <Modal
                                    open={isCheckListModalOpen}
                                    onClose={handleCheckListModalClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style}>
                                        <Typography id="modal-modal-title" variant="h5" component="h2">Add CheckLists</Typography>
                                        <Box marginTop={3}>
                                            <DataGrid
                                                style={{ backgroundColor: appColor.white }}
                                                rows={checkLists.filter(x => x.status === 5)}
                                                columns={columns.filter(x => !x.hidden)}
                                                initialState={{
                                                    pagination: {
                                                        paginationModel: {
                                                            pageSize: 10,
                                                        },
                                                    },
                                                }}
                                                slots={{
                                                    toolbar: CustomToolbar,
                                                }}
                                                pageSizeOptions={[10]}
                                                disableColumnFilter
                                                disableColumnSelector
                                                disableDensitySelector
                                                disableRowSelectionOnClick
                                                fullWidth
                                            />
                                        </Box>
                                        <Box marginTop={3} display="flex" alignItems="center">
                                            <Button variant="contained" color="error" disableElevation onClick={handleCheckListModalClose}>Close</Button>
                                        </Box>
                                    </Box>
                                </Modal>

                                {/*modal per l'edit checklist*/}
                                <Modal
                                    open={isCheckListEditModalOpen}
                                    onClose={handleCheckListEditModalClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style}>
                                        <Typography id="modal-modal-title" variant="h5" component="h2">Remove CheckList Indicator for CheckList: {checkListSelectedDesc}</Typography>
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Item</TableCell>
                                                        <TableCell>Description</TableCell>
                                                        <TableCell>Weighting (%)</TableCell>
                                                        <TableCell>Number Of Ratings</TableCell>
                                                        <TableCell>Reason for deleting</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {checkListIndicators.sort((a, b) => a.item.localeCompare(b.item)).map(indicator => {
                                                        const isRemoved = removedIndicators[indicator.id];
                                                        return (
                                                            <TableRow key={indicator.id}>
                                                                <TableCell style={{ width: 100, fontWeight: 'bold' }}>{indicator.item}</TableCell>
                                                                <TableCell style={{ width: 250, fontWeight: 'bold' }}>{indicator.description}</TableCell>
                                                                <TableCell style={{ width: 150, fontWeight: 'bold' }}>{indicator.weight}%</TableCell>
                                                                <TableCell style={{ width: 150, fontWeight: 'bold' }}>{indicator.numberOfRatings}</TableCell>
                                                                <TableCell style={{ width: 400, fontWeight: 'bold' }}>
                                                                    <FormControl size="small" fullWidth>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            margin="dense"
                                                                            helperText={noteErrors[indicator.id] ? "Please enter a note with a minimum of 3 charachter" : ""}
                                                                            error={noteErrors[indicator.id]}
                                                                            value={isRemoved ? isRemoved.note : ""}
                                                                            onChange={(event) => handleNoteChange(event, indicator.id)}
                                                                            InputProps={{ style: { height: '30px' } }}
                                                                            style={{ marginBottom: 10 }}
                                                                        />
                                                                    </FormControl>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {isRemoved && (

                                                                        <RestoreIcon onClick={() => handleToggleIndicator(indicator)} /> 
                                                                    )
                                                                        //<DeleteIcon onClick={() => handleToggleIndicator({ ...indicator, note: removedIndicators[indicator.id]?.note || "" })} />
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Box marginTop={3} display="flex" alignItems="center">
                                            <Button variant="contained" color="success" disableElevation onClick={handleCheckListEditModalClose} style={{ marginRight: 15 }}><SaveIcon style={{ marginRight: 15 }} />Save</Button>
                                            <Button variant="contained" color="error" disableElevation onClick={() => handleCheckListEditModalClose(checkListSelectedId)}>Cancel</Button>
                                        </Box>
                                    </Box>
                                </Modal>
                                <Modal
                                    open={isWarningModalOpen}
                                    onClose={handleWarningModalClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style}>
                                        <Typography id="modal-modal-title" variant="h5" component="h2" marginBottom={2}>Warning</Typography>
                                        <p>Before saving this Audit you have to compile all the mandatory fields and add at least one CheckList.</p>
                                        <Box marginTop={3} display="flex" alignItems="center">
                                            <Button variant="contained" color="error" disableElevation onClick={handleWarningModalClose}>Close</Button>
                                        </Box>
                                    </Box>
                                </Modal>
                                <LoadingButton
                                    type="submit"
                                    style={{ marginTop: 40 }}
                                    loading={loadingButton}
                                    loadingPosition='start'
                                    startIcon={<SaveIcon />}
                                    color='success'
                                    variant='contained'
                                    disableElevation
                                >
                                    { audit ? 'Update' : 'Create'}
                                </LoadingButton>
                            </Form>
                        )}
                    </Formik>                    
                </div>              
            </Paper>
        </Box>        
    );
}

export default CreateAuditPage;