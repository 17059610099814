import React, { useState, useEffect } from 'react';
import { Box, Typography, Skeleton, Button } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import appColor from '../../Style/appColor';
import * as XLSX from 'xlsx';
import { ApiUrl } from '../../ApiUrl';
import { AuditType } from '../../Model/Enum/AuditType';
import { AuditStatus } from '../../Model/Enum/AuditStatus';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { GetApp } from '@mui/icons-material';
import { convertDate } from '../../Utils/convertDate';

function AuditInProgressPage() {
    const navigate = useNavigate();
    const [audits, setAudits] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(getAuditList, []);

    function getAuditList() {
        fetch(ApiUrl.auditEndpoint + "/GetAllFromView?statuses=5,6")
            .then(response => response.json())
            .then(json => {
                setAudits(json)
                setLoading(false)
            })
            .catch(error => {
                console.error(error)
                setLoading(false);
            });
    }
    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <Button style={{ cursor: 'pointer', margin: '0 10px' }} onClick={() => exportToExcel()}>
                    <GetApp style={{marginRight: 10}} />
                    EXPORT
                </Button>
            </GridToolbarContainer>
        );
    }

    const exportToExcel = () => {
        const headers = columns.filter(column => column.field !== 'action').map(column => column.headerName);
  
        const worksheet = XLSX.utils.json_to_sheet([headers, ...audits.map(rowData =>
        columns.map(column => {
            switch (column.field) {
                case 'status':
                    return AuditStatus[rowData[column.field]];
                case 'type':
                    return AuditType[rowData[column.field]];
                default:
                    return column.valueGetter ? column.valueGetter({ row: rowData }) : rowData[column.field];
            }         
        })
        )], { skipHeader: true });
        
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'AuditInProgress');
        XLSX.writeFile(workbook, 'AuditInProgressList.xlsx');
    };    

    const columns = [
        {
            field: 'auditId',
            headerName: 'Id',
            width: 70,
            renderCell: (params) => params.row.auditId
        },
        {
            field: 'auditName',
            headerName: 'Title',
            width: 150,
            renderCell: (params) => params.row.auditName
        },
        {
            field: 'auditStatus',
            headerName: 'Status',
            width: 100,
            renderCell: (params) => params.row.auditStatus
        },
        {
            field: 'auditType',
            headerName: 'Type',
            width: 90,
            renderCell: (params) => params.row.auditType
        },
        {
            field: 'plantName',
            headerName: 'Plant',
            width: 120,
            valueGetter: (params) => params.row.plantName
        },
        {
            field: 'departmentName',
            headerName: 'Department',
            width: 120,
            valueGetter: (params) => params.row.departmentName
        },
        {
            field: 'areaNames',
            headerName: 'Area',
            width: 120,
            valueGetter: (params) => params.row.areaNames
        },
        {
            field: 'lineNames',
            headerName: 'Line',
            width: 120,
            valueGetter: (params) => params.row.lineNames
        },
        {
            field: 'machineNames',
            headerName: 'Machine',
            width: 120,
            valueGetter: (params) => params.row.machineNames
        },
        {
            field: 'auditStartDate',
            headerName: 'Start Date',
            width: 120,
            valueGetter: (params) => convertDate(params.row.auditStartDate)
        },
        {
            field: 'endDate',
            headerName: 'End Date',
            width: 120,
            valueGetter: (params) => convertDate(params.row.endDate)
        },
        {
            field: 'dateCompleted',
            headerName: 'Date Completed',
            width: 120,
            valueGetter: (params) => convertDate(params.row.dateCompleted)
        },
        {
            field: 'filingDate',
            headerName: 'Filing Date',
            width: 120,
            valueGetter: (params) => convertDate(params.row.filingDate)
        },
        {
            field: 'leadAuditorEmail',
            headerName: 'Lead Auditor',
            width: 150,
            valueGetter: (params) => params.row.leadAuditorEmail
        },
        {
            field: 'countdown',
            headerName: 'Countdown',
            width: 130,
            valueGetter: (params) => params.row.countdown + " days"
        },
        {
            field: 'checkListCount',
            headerName: 'CheckList Count',
            width: 120
        },
        {
            field: 'action',
            headerName: 'Actions',
            width: 130,
            renderCell: (params) => (
                <div>
                    <EditNoteIcon style={{ cursor: 'pointer', marginLeft: 15 }} onClick={() => navigate(`edit-checklist/${params.row.auditId}/${false}`)} />
                    <VisibilityIcon style={{ cursor: 'pointer', marginLeft: 15 }} onClick={() => navigate(`edit-checklist/${params.row.auditId}/${true}`)} />
                </div>
            )
        }
    ];

    return (
        <Box>
            <Box display="flex" alignItems="center" style={{ marginBottom: 20 }}>
                <Typography variant="h4">Audit in Progress</Typography>
            </Box>

            {loading ? (
                <>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                </>
            ) : (
                audits.length === 0 ? 
                <Typography variant="h6" style={{ marginRight: 30 }}>No Audit yet</Typography> :
                <DataGrid
                    style={{ backgroundColor: appColor.white }}
                    rows={audits}
                    columns={columns}
                    getRowId={(row) => row.auditId}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                    pageSizeOptions={[10]}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableRowSelectionOnClick
                />)}
        </Box>
    );
}

export default AuditInProgressPage;
