import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Button, Select,InputLabel, MenuItem, Alert, FormControl, Modal, Table, TableBody, TableCell, TableContainer, Skeleton, TableHead, TableRow, Paper, TextField, CircularProgress } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as XLSX from 'xlsx';
import Snackbar from '@mui/material/Snackbar';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import appColor from '../../Style/appColor';
import { ApiUrl } from '../../ApiUrl';
import { AuditCheckListCorrectiveAction } from '../../Model/AuditCheckListCorrectiveAction';
import { AuditCheckListBestPractice } from '../../Model/AuditCheckListBestPractice';
import { useParams } from "react-router";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppsIcon from '@mui/icons-material/Apps';
import { CheckListType } from '../../Model/Enum/CheckListType';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import RestoreIcon from '@mui/icons-material/Restore';
import LoginIcon from '@mui/icons-material/Login';
import { useAuth } from "../../Hooks/AuthProvider";
import { GetApp } from '@mui/icons-material';
import ChecklistIcon from '@mui/icons-material/Checklist';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const buttonStyle = {
    height: 150,
    width: 150,
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: 1,
    '&:hover': {
        borderColor: '#000',
    },
}

const buttonBPStyle = {
    height: 220,
    width: 595,
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: 1,
    '&:hover': {
        borderColor: '#000',
    },
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function EditAuditCheckListPage() {
    const auth = useAuth();
    const { auditId } = useParams();
    const { readOnly } = useParams();
    const navigate = useNavigate();
    const navigateBack = () => navigate('/audit-progress');

    const [audit, setAudit] = useState();
    const [checkListDisabled, setCheckListDisabled] = useState([]);
    const [users, setUsers] = useState([]);
    const [checkListList, setCheckListList] = useState([]);
    const [checkListIndicatorTable, setCheckListIndicatorTable] = useState([]);
    const [checkListIdSelectedRow, setCheckListIdSelectedRow] = useState(0);
    const [auditButtonSelectedId, setAuditButtonSelectedId] = useState(0);

    const [checkListIndicatorList, setCheckListIndicatorList] = useState([]);
    const [checkListSpecificIndicatorList, setCheckListSpecificIndicatorList] = useState([]);
    const [checkListRateList, setCheckListRateList] = useState([]);
    const [checkListCount, setCheckListCount] = useState([]);

    const [auditCheckListCorrectiveActions, setAuditCheckListCorrectiveActions] = useState([]);
    const [auditCheckListBestPractices, setAuditCheckListBestPractices] = useState([]);

    const [viewOnly, setViewOnly] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [loadingIndicatorTable, setLoadingIndicatorTable] = useState(false);
    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [loadingChecklist, setLoadingChecklist] = useState(false);
    const [isCompileModalOpen, setIsCompileModalOpen] = useState(false);

    const [auditCheckListId, setAuditCheckListId] = useState(0);
    const [machineOrAreaCode, setMachineOrAreaCode] = useState("");
    const [typeAlert, setTypeAlert] = useState("");
    const [messageAlert, setMessageAlert] = useState("");
    const [checkListSelected, setCheckListSelected] = useState({});
    const [indiCompileSelected, setIndiCompileSelected] = useState({});
    const [auditCheckListSelected, setAuditCheckListSelected] = useState({});
    //added
    const [areaId, setSelectedAreaId] = useState(0);
    const [lineId, setSelectedLineId] = useState(0);
    const [machineId, setSelectedMachineId] = useState(0);


    const [formData, setFormData] = useState({
        id:null,
        indicatorId: '',
        specificIndicatorId: '',
        leadAuditor: '',
        date: dayjs(new Date()),
        auditorRemarks: '',
        actionRequestedByAuditor: '',
        responsabile: '',
        dueDate: null,
        replyOfResponsibleOnAction: '',
        replyAcceptanceByAuditor: '',
        reasonForRejection: '',
        images: {
            image1: null,
            image2: null,
            image3: null,
            image4: null,
            image5: null
        }
    });

    const [isBestPraticeModalOpen, setIsBestPraticeModalOpen] = useState(false);
    const [formBestPracticeData, setFormBestPracticeData] = useState({
        id:null,
        indicatorId: '',
        specificIndicatorId: '',
        createdBy: '',
        date: dayjs(new Date()),
        descriptionAudit: '',
        description: '',
        previousSituation: '',
        nextSituation: '',
        expectedBenefit: '',
        image: null,
    });
    const [bestPracticErrors, setBestPracticErrors] = useState({});
    const [formCompiledData, setFormCompiledData] = useState([{


    }]);
    const [errors, setErrors] = useState({});

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleBPChange = (event) => {
        const { name, value } = event.target;
        setFormBestPracticeData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleDateChange = (date, name) => {
        console.log("date: ", date);
        const momentDate = moment(new Date(date)).format();
        console.log("moment date: ", momentDate);
        setFormData(prevState => ({
            ...prevState,
            [name]: momentDate
        }));
    };

    const handleImageChange = (event, imageNumber) => {
        const files = event.target.files;
        if (files.length > 0) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setFormData(prevState => ({
                    ...prevState,
                    images: {
                        ...prevState.images,
                        [`image${imageNumber}`]: e.target.result
                    }
                }));
            };
            reader.readAsDataURL(files[0]);
        }
    };

    const handleBPImageChange = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setFormBestPracticeData(prevState => ({
                    ...prevState,
                    image: e.target.result
                }));
            };
            reader.readAsDataURL(files[0]);
        }
    };

    const getCheckColor = (indicator, typeIndicator) => {
        //edited
        if (typeIndicator === 'indicator') {
            const correctiveAction = auditCheckListCorrectiveActions.find(x => x.indicatorId === indicator.id && x.asset === machineOrAreaCode);

            if (correctiveAction) {
                if (correctiveAction.status === 'Created') {
                    return '#db0a0a';
                }
                if (correctiveAction.status === 'Completed') {
                    return '#db6c0a';
                }
                if (correctiveAction.status === 'Finished') {
                    return '#2e7d32';
                }
                return true;
            }
        }

        if (typeIndicator === 'specificIndicator') {
            const correctiveAction = auditCheckListCorrectiveActions.find(x => x.specificIndicatorId === indicator.id && x.asset === machineOrAreaCode);

            if (correctiveAction) {
                if (correctiveAction.status === 'Created') {
                    return '#db0a0a';
                }
                if (correctiveAction.status === 'Completed') {
                    return '#db6c0a';
                }
                if (correctiveAction.status === 'Finished') {
                    return '#2e7d32';
                }
            }
        }

        return '#2e7d32';
    }


    const hasCorrectiveAction = (indicator, typeIndicator) => {
        //added
        if (typeIndicator === 'indicator') {
            const correctiveAction = auditCheckListCorrectiveActions.find(x => x.indicatorId === indicator.id && x.asset === machineOrAreaCode);

            if (correctiveAction) {
                return true;
            }
        }
        if (typeIndicator === 'specificIndicator') {
            const correctiveAction = auditCheckListCorrectiveActions.find(x => x.specificIndicatorId === indicator.id && x.asset === machineOrAreaCode);

            if (correctiveAction) {
                return true;
            }
        }

        return false;
    }

    const handleSubmit = () => {
        const newErrors = {};
        if (formData.auditorRemarks.trim() === '') {
            newErrors.auditorRemarks = 'Auditor Remarks is required';
        }
        if (formData.responsabile.trim() === '') {
            newErrors.responsabile = 'Responsabile is required';
        }
        if (!formData.dueDate) {
            newErrors.dueDate = 'Due date is required';
        }
        if (formData.actionRequestedByAuditor.trim() === '') {
            newErrors.actionRequestedByAuditor = 'Action Requested By Auditor is required';
        }
        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            return;
        }
        const auditCheckListCorrectiveAction = new AuditCheckListCorrectiveAction();

        if (formData.id !== null) {
            auditCheckListCorrectiveAction.id = formData.id
        }
        auditCheckListCorrectiveAction.auditCheckListId = auditCheckListId;
        auditCheckListCorrectiveAction.indicatorId = formData.indicatorId;
        auditCheckListCorrectiveAction.specificIndicatorId = formData.specificIndicatorId;
        auditCheckListCorrectiveAction.leadAuditor = formData.leadAuditor;
        auditCheckListCorrectiveAction.date = formData.date;
        auditCheckListCorrectiveAction.auditorRemarks = formData.auditorRemarks;
        auditCheckListCorrectiveAction.actionRequestedByAuditor = formData.actionRequestedByAuditor;
        auditCheckListCorrectiveAction.responsabile = formData.responsabile;
        auditCheckListCorrectiveAction.dueDate = formData.dueDate;
        auditCheckListCorrectiveAction.replyOfResponsibleOnAction = formData.replyOfResponsibleOnAction
        auditCheckListCorrectiveAction.replyAcceptanceByAuditor = formData.replyAcceptanceByAuditor;
        auditCheckListCorrectiveAction.reasonForRejection = '';
        auditCheckListCorrectiveAction.image1 = formData.images.image1;
        auditCheckListCorrectiveAction.image2 = formData.images.image2;
        auditCheckListCorrectiveAction.image3 = formData.images.image3;
        auditCheckListCorrectiveAction.image4 = formData.images.image4;
        auditCheckListCorrectiveAction.image5 = formData.images.image5;
        auditCheckListCorrectiveAction.status = 'Created';
        //added
        auditCheckListCorrectiveAction.areaId = areaId;
        auditCheckListCorrectiveAction.lineId = lineId;
        auditCheckListCorrectiveAction.machineId = machineId;
        auditCheckListCorrectiveAction.asset = machineOrAreaCode;

        if (auth) {
            auditCheckListCorrectiveAction.id == null ?
                auditCheckListCorrectiveAction.createdBy = auth.user?.id :
                auditCheckListCorrectiveAction.modifiedBy = auth.user?.id
        }
        console.log("due date ", auditCheckListCorrectiveAction.dueDate)
        fetch(ApiUrl.auditEndpoint + "/AuditCheckListCorrectiveAction", {
            method: auditCheckListCorrectiveAction.id == null ? 'POST' : 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(auditCheckListCorrectiveAction),
        })
        .then(response => response.json())
        .then(() => {
            setIsCompileModalOpen(false);
            setFormData({
                id: null,
                indicatorId: '',
                specificIndicatorId: '',
                leadAuditor: '',
                date: dayjs(new Date()),
                auditorRemarks: '',
                actionRequestedByAuditor: '',
                responsabile: '',
                dueDate: null,
                replyOfResponsibleOnAction: '',
                replyAcceptanceByAuditor: '',
                reasonForRejection: '',
                images: {
                    image1: null,
                    image2: null,
                    image3: null,
                    image4: null,
                    image5: null
                }
            });
            getAuditCheckListCorrectiveActions(checkListIdSelectedRow)
        })
        .catch(error => {
            console.error('Error submitting corrective action:', error);
        });
    }

    const handleBestPracticeSubmit = () => {
        const newErrors = {};
        if (formBestPracticeData.description.trim() === '') {
            newErrors.description = 'Description is required';
        } else if (formBestPracticeData.description.length < 10 || formBestPracticeData.description.length > 100) {
            newErrors.description = 'Description must be between 10 and 100 characters';
        }
        if (formBestPracticeData.previousSituation.trim() === '') {
            newErrors.previousSituation = 'Previous Situation is required';
        } else if (formBestPracticeData.previousSituation.length < 10 || formBestPracticeData.previousSituation.length > 600) {
            newErrors.previousSituation = 'Previous Situation must be between 10 and 600 characters';
        }
        if (formBestPracticeData.nextSituation.trim() === '') {
            newErrors.nextSituation = 'Next Situation is required';
        } else if (formBestPracticeData.nextSituation.length < 10 || formBestPracticeData.nextSituation.length > 600) {
            newErrors.nextSituation = 'Next Situation must be between 10 and 600 characters';
        }
        if (formBestPracticeData.expectedBenefit.trim() === '') {
            newErrors.expectedBenefit = 'Expected Benefit By Auditor is required';
        } else if (formBestPracticeData.expectedBenefit.length < 10 || formBestPracticeData.expectedBenefit.length > 300) {
            newErrors.expectedBenefit = 'Expected Benefit must be between 10 and 300 characters';
        }
        setBestPracticErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            return;
        }
        const auditCheckListBestPractice = new AuditCheckListBestPractice();

        if (formBestPracticeData.id !== null) {
            auditCheckListBestPractice.id = formBestPracticeData.id
        }
        auditCheckListBestPractice.auditCheckListId = auditCheckListId;
        auditCheckListBestPractice.indicatorId = formBestPracticeData.indicatorId;
        auditCheckListBestPractice.specificIndicatorId = formBestPracticeData.specificIndicatorId;
        auditCheckListBestPractice.createdByEmail = formBestPracticeData.createdBy;
        auditCheckListBestPractice.date = formBestPracticeData.date
        auditCheckListBestPractice.image = formBestPracticeData.image;
        auditCheckListBestPractice.description = formBestPracticeData.description;
        auditCheckListBestPractice.previousSituation = formBestPracticeData.previousSituation;
        auditCheckListBestPractice.nextSituation = formBestPracticeData.nextSituation;
        auditCheckListBestPractice.expectedBenefit = formBestPracticeData.expectedBenefit;
        //added
        auditCheckListBestPractice.areaId = areaId;
        auditCheckListBestPractice.lineId = lineId;
        auditCheckListBestPractice.machineId = machineId;
        auditCheckListBestPractice.asset = machineOrAreaCode;

        if (auth) {
            auditCheckListBestPractice.id == null ?
                auditCheckListBestPractice.createdBy = auth.user?.id :
                auditCheckListBestPractice.modifiedBy = auth.user?.id

        }
        console.log('auditCheckListBestPractice', auditCheckListBestPractice)
        
        fetch(ApiUrl.auditEndpoint + "/AuditCheckListBestPractice", {
            method: auditCheckListBestPractice.id == null ? 'POST' : 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(auditCheckListBestPractice),
        })
        .then(response => response.json())
        .then(() => {
            setIsBestPraticeModalOpen(false);
            setFormBestPracticeData({
                id: null,
                auditCheckListId: '',
                specificIndicatorId: '',
                indicatorId: '',
                date: dayjs(new Date()),
                createdBy: '',
                description: '',
                previousSituation: '',
                nextSituation: '',
                expectedBenefit: '',
                image: null,
            })
            getAuditCheckListBestPractices(checkListIdSelectedRow)
        })
        .catch(error => {
            console.error('Error submitting best practice:', error);
        });
    }

    const hasBestPractice = (indicator, typeIndicator) => {
        if (typeIndicator === 'indicator') {
            const bestPractice = auditCheckListBestPractices.find(x => x.indicatorId === indicator.id && x.asset === machineOrAreaCode);

            if (bestPractice) {
                return true;
            }
        }
        if (typeIndicator === 'specificIndicator') {
            const bestPractice = auditCheckListBestPractices.find(x => x.specificIndicatorId === indicator.id && x.asset === machineOrAreaCode);

            if (bestPractice) {
                return true;
            }
        }

        return false;
    }

    const handleCompileModalOpen = (indicator, typeIndicator) => {
        //edited
        const checkList = checkListList.find(x => x.id === checkListIdSelectedRow)
        setCheckListSelected(checkList)
        setIndiCompileSelected(indicator)
        fetch(ApiUrl.auditEndpoint + "/AuditCheckListId/" + auditId + "/" + checkListIdSelectedRow)
            .then(response => response.json())
            .then(json => setAuditCheckListId(json))
            .catch(error => console.error(error));

        const auditCheckList = auditCheckListCorrectiveActions.find(x => typeIndicator === 'indicator' ?
                                                                    x.indicatorId === indicator.id && x.asset === machineOrAreaCode :
                                                                    x.specificIndicatorId === indicator.id && x.asset === machineOrAreaCode)
        if (auditCheckList) {
            console.log("Audit checklist dueDate ", auditCheckList.dueDate)
            console.log("Audit checklist dueDate dayJs", dayjs(auditCheckList.dueDate))
            setFormData({
                id: auditCheckList.id,
                leadAuditor: auditCheckList.leadAuditor,
                indicatorId: typeIndicator === 'indicator' ? auditCheckList.indicatorId : null,
                specificIndicatorId: typeIndicator === 'specificIndicator' ? auditCheckList.specificIndicatorId : null,
                date: dayjs(auditCheckList.date).startOf('day'),
                auditorRemarks: auditCheckList.auditorRemarks,
                actionRequestedByAuditor: auditCheckList.actionRequestedByAuditor,
                responsabile: auditCheckList.responsabile,
                dueDate: dayjs(auditCheckList.dueDate).startOf('day'),
                replyOfResponsibleOnAction: auditCheckList.replyOfResponsibleOnAction,
                replyAcceptanceByAuditor: auditCheckList.replyAcceptanceByAuditor,
                reasonForRejection: auditCheckList.reasonForRejection,
                images: {
                    image1: `data:image/png;base64,${auditCheckList.image1}`,
                    image2: `data:image/png;base64,${auditCheckList.image2}`,
                    image3: `data:image/png;base64,${auditCheckList.image3}`,
                    image4: `data:image/png;base64,${auditCheckList.image4}`,
                    image5: `data:image/png;base64,${auditCheckList.image5}`
                }
            });

        } else {
            setFormData({
                id: null,
                leadAuditor: audit.leadAuditor.email,
                indicatorId: typeIndicator === 'indicator' ? indicator.id : null,
                specificIndicatorId: typeIndicator === 'specificIndicator' ? indicator.id : null,
                date: dayjs(new Date()),
                auditorRemarks: '',
                actionRequestedByAuditor: '',
                responsabile: '',
                dueDate: null,
                replyOfResponsibleOnAction: '',
                replyAcceptanceByAuditor: '',
                reasonForRejection: '',
                images: {
                    image1: null,
                    image2: null,
                    image3: null,
                    image4: null,
                    image5: null
                }
            });
        }


        setIsCompileModalOpen(true);
        
    }

    const handleCompileModalOpenViewOnly = (indicator, typeIndicator) => {
        const checkList = checkListList.find(x => x.id === checkListIdSelectedRow)
        setCheckListSelected(checkList)
        setIndiCompileSelected(indicator)
        fetch(ApiUrl.auditEndpoint + "/AuditCheckListId/" + auditId + "/" + checkListIdSelectedRow)
            .then(response => response.json())
            .then(json => setAuditCheckListId(json))
            .catch(error => console.error(error));
        if (typeIndicator === 'indicator') {
            const correctiveAction = auditCheckListCorrectiveActions.find(x => x.indicatorId === indicator.id && x.asset === machineOrAreaCode);

            if (correctiveAction) {
                setFormData({
                    leadAuditor: audit.leadAuditor.email,
                    indicatorId: typeIndicator === 'indicator' ? indicator.id : null,
                    specificIndicatorId: typeIndicator === 'specificIndicator' ? indicator.id : null,
                    date: dayjs(correctiveAction.date),
                    auditorRemarks: correctiveAction.auditorRemarks,
                    actionRequestedByAuditor: correctiveAction.actionRequestedByAuditor,
                    responsabile: correctiveAction.responsabile,
                    dueDate: dayjs(correctiveAction.dueDate),
                    replyOfResponsibleOnAction: correctiveAction.replyOfResponsibleOnAction,
                    replyAcceptanceByAuditor: correctiveAction.replyAcceptanceByAuditor,
                    reasonForRejection: correctiveAction.reasonForRejection,
                    images: {
                        image1: `data:image/png;base64,${correctiveAction.image1}`,
                        image2: `data:image/png;base64,${correctiveAction.image2}`,
                        image3: `data:image/png;base64,${correctiveAction.image3}`,
                        image4: `data:image/png;base64,${correctiveAction.image4}`,
                        image5: `data:image/png;base64,${correctiveAction.image5}`
                    }
                });
            }
        }
        if (typeIndicator === 'specificIndicator') {
            const correctiveAction = auditCheckListCorrectiveActions.find(x => x.specificIndicatorId === indicator.id && x.asset === machineOrAreaCode);

            if (correctiveAction) {
                setFormData({
                    leadAuditor: audit.leadAuditor.email,
                    indicatorId: typeIndicator === 'indicator' ? indicator.id : '',
                    specificIndicatorId: typeIndicator === 'specificIndicator' ? indicator.id : '',
                    date: dayjs(correctiveAction.date),
                    auditorRemarks: correctiveAction.auditorRemarks,
                    actionRequestedByAuditor: correctiveAction.actionRequestedByAuditor,
                    responsabile: correctiveAction.responsabile,
                    dueDate: dayjs(correctiveAction.dueDate),
                    replyOfResponsibleOnAction: correctiveAction.replyOfResponsibleOnAction,
                    replyAcceptanceByAuditor: correctiveAction.replyAcceptanceByAuditor,
                    reasonForRejection: correctiveAction.reasonForRejection,
                    images: {
                        image1: `data:image/png;base64,${correctiveAction.image1}`,
                        image2: `data:image/png;base64,${correctiveAction.image2}`,
                        image3: `data:image/png;base64,${correctiveAction.image3}`,
                        image4: `data:image/png;base64,${correctiveAction.image4}`,
                        image5: `data:image/png;base64,${correctiveAction.image5}`
                    }
                });
            }
        }
        setViewOnly(true)
        setIsCompileModalOpen(true)
    }

    const handleCompileModalClose = () => {
        setIsCompileModalOpen(false)
        setViewOnly(false)
        setFormData({
            id: null,
            auditCheckListId: '',
            specificIndicatorId: '',
            indicatorId: '',
            leadAuditor: '',
            date: dayjs(new Date()),
            auditorRemarks: '',
            actionRequestedByAuditor: '',
            responsabile: '',
            dueDate: null,
            replyOfResponsibleOnAction: '',
            replyAcceptanceByAuditor: '',
            reasonForRejection: '',
            images: {
                image1: null,
                image2: null,
                image3: null,
                image4: null,
                image5: null
            }
        })
        setErrors({});
    }

    const handleBestPracticeModalClose = () => {
        setIsBestPraticeModalOpen(false)
        setViewOnly(false)
        setFormBestPracticeData({
            id: null,
            auditCheckListId: '',
            specificIndicatorId: '',
            indicatorId: '',
            date: dayjs(new Date()),
            createdBy: '',
            description: '',
            previousSituation: '',
            nextSituation: '',
            expectedBenefit: '',
            image: null,
        })
        setBestPracticErrors({});
    }   

    const handleBestPracticeModalOpen = (indicator, typeIndicator) => {
        setBestPracticErrors({});
        const checkList = checkListList.find(x => x.id === checkListIdSelectedRow)
        setCheckListSelected(checkList)
        setIndiCompileSelected(indicator)
        fetch(ApiUrl.auditEndpoint + "/AuditCheckListId/" + auditId + "/" + checkListIdSelectedRow)
            .then(response => response.json())
            .then(json => setAuditCheckListId(json))
            .catch(error => console.error(error));

        const auditCheckList = auditCheckListBestPractices.find(x => typeIndicator === 'indicator' ?
            x.indicatorId === indicator.id && x.asset === machineOrAreaCode :
            x.specificIndicatorId === indicator.id && x.asset === machineOrAreaCode)
        if (auditCheckList) {
            if (auditCheckList.createdByEmail !== auth.user?.email) {
                setViewOnly(true)
            }
            if (readOnly === 'true') {
                setViewOnly(true)
            }
            setFormBestPracticeData({
                id: auditCheckList.id,
                indicatorId: typeIndicator === 'indicator' ? auditCheckList.indicatorId : null,
                specificIndicatorId: typeIndicator === 'specificIndicator' ? auditCheckList.specificIndicatorId : null,
                date: dayjs(auditCheckList.date),
                description: auditCheckList.description,
                createdBy: auditCheckList.createdByEmail,
                previousSituation: auditCheckList.previousSituation,
                nextSituation: auditCheckList.nextSituation,
                expectedBenefit: auditCheckList.expectedBenefit,
                image: `data:image/png;base64,${auditCheckList.image}`,
            });
        } else {
        setFormBestPracticeData({
                id: null,
                indicatorId: typeIndicator === 'indicator' ? indicator.id : null,
                specificIndicatorId: typeIndicator === 'specificIndicator' ? indicator.id : null,
                date: dayjs(new Date()),
                createdBy: auth.user ? auth.user?.email : '',
                description: '',
                previousSituation: '',
                nextSituation: '',
                expectedBenefit: '',
                image: null,
            });
        }


        setIsBestPraticeModalOpen(true);

    }

    const getAuditById = () => {
        setLoading(true);
        fetch(ApiUrl.auditEndpoint + "/" + auditId)
            .then(response => response.json())
            .then(json => {
                console.log('jsonegetdsuaitbyid', json)
                setAudit(json);
                setFormFinalNotesData({
                    id: json.id,
                    notes: json.finalNotes
                });
                //if(json.area.length > 0) {
                //    setAuditButtonSelectedId(json.area[0].id);
                //    var codeAndDesc = `${json.area[0].name}${json.area[0].code ? ` (${json.area[0].code})` : ''}`
                //    setMachineOrAreaCode(codeAndDesc);
                //    setSelectedAreaId(json.area[0].id)
                //}
                //if(json.line.length > 0) {
                //    setAuditButtonSelectedId(json.line[0].id);
                //    var codeAndDesc3 = `${json.line[0].name}${json.line[0].code ? ` (${json.line[0].code})` : ''}`
                //    setMachineOrAreaCode(codeAndDesc3)
                //    setSelectedAreaId(0)
                //    setSelectedLineId(json.line[0].id)
                //}
                if(json.machine.length > 0) {
                    setAuditButtonSelectedId(json.machine[0].id);
                    var codeAndDesc2 = `${json.machine[0].name}${json.machine[0].code ? ` (${json.machine[0].code})` : ''}`
                    setMachineOrAreaCode(codeAndDesc2);
                    setSelectedAreaId(0)
                    setSelectedLineId(0)
                    setSelectedMachineId(json.machine[0].id);
                }
            })
            .catch(error => {
                console.error(error)
                setLoading(false);
            });
    }

    const getCheckListDisabled = () => {
        fetch(ApiUrl.auditEndpoint + "/CheckListDisabledAudit/" + auditId)
            .then(response => response.json())
            .then(json => setCheckListDisabled(json))
            .catch(error => console.error(error));
    }

    const getCheckListCompiledIndicators = (id, indicators, specIndicators) => {
        fetch(ApiUrl.auditEndpoint + "/AuditCompiledIndicators/" + auditId + "/" + id)
            .then(response => response.json())
            .then(json => {
                setAuditCheckListSelected(json);
                const groupedData = json.reduce((acc, obj) => {
                    const elementId = obj.elementId;
                    if (!acc[elementId]) {
                        acc[elementId] = [];
                    }
                    acc[elementId].push(obj);
                    return acc;
                }, {});
                const groupedArray = Object.keys(groupedData).map(key => ({
                    elementId: key,
                    items: groupedData[key],
                    initialized: false
                }));

                if (audit.machine.length > 0) {
                    audit.machine.forEach(machine => {
                        const existingElement = groupedArray.find(item => item.elementId === machine.id.toString());
                        if (!existingElement) {
                            const newElement = {
                                elementId: machine.id.toString(),
                                items: [],
                                initialized: true,
                            };

                            const filteredIndicatorsIds = specIndicators.map(x => x.checkListIndicator.id);
                            const filteredIndicators = indicators.filter(indicator => !filteredIndicatorsIds.includes(indicator.id));

                            filteredIndicators.forEach(indicator => {
                                const newItem = {
                                    indicatorId: indicator.id,
                                    elementId: machine.id,
                                    checkListId: indicator.checkListId,
                                    rate: '',
                                    score: 0,
                                    initialized: true
                                };
                                newElement.items.push(newItem);
                            });

                            specIndicators.forEach(specificIndicator => {
                                const newItem = {
                                    elementId: machine.id,
                                    specificIndicatorId: specificIndicator.id,
                                    checkListId: specificIndicator.checkListIndicator.checkListId,
                                    rate: '',
                                    score: 0,
                                    initialized: true
                                };
                                newElement.items.push(newItem);
                            });

                            groupedArray.push(newElement);
                        }
                    });
                }
                else if (audit.line.length > 0) {
                    audit.line.forEach(line => {
                        const existingElement = groupedArray.find(item => item.elementId === line.id.toString());
                        if (!existingElement) {
                            const newElement = {
                                elementId: line.id.toString(),
                                items: []
                            };

                            const filteredIndicatorsIds = specIndicators.map(x => x.checkListIndicator.id);
                            const filteredIndicators = indicators.filter(indicator => !filteredIndicatorsIds.includes(indicator.id));

                            filteredIndicators.forEach(indicator => {
                                const newItem = {
                                    indicatorId: indicator.id,
                                    elementId: line.id,
                                    checkListId: indicator.checkListId,
                                    rate: '',
                                    score: 0
                                };
                                newElement.items.push(newItem);
                            });

                            specIndicators.forEach(specificIndicator => {
                                const newItem = {
                                    elementId: line.id,
                                    specificIndicatorId: specificIndicator.id,
                                    checkListId: specificIndicator.checkListIndicator.checkListId,
                                    rate: '',
                                    score: 0
                                };
                                newElement.items.push(newItem);
                            });

                            groupedArray.push(newElement);
                        }
                    });
                }
                else if (audit.area.length > 0) {
                    audit.area.forEach(area => {
                        const existingElement = groupedArray.find(item => item.elementId === area.id.toString());
                        if (!existingElement) {
                            const newElement = {
                                elementId: area.id.toString(),
                                items: []
                            };

                            const filteredIndicatorsIds = specIndicators.map(x => x.checkListIndicator.id);
                            const filteredIndicators = indicators.filter(indicator => !filteredIndicatorsIds.includes(indicator.id));

                            filteredIndicators.forEach(indicator => {
                                const newItem = {
                                    indicatorId: indicator.id,
                                    elementId: area.id,
                                    checkListId: indicator.checkListId,
                                    rate: '',
                                    score: 0
                                };
                                newElement.items.push(newItem);
                            });

                            specIndicators.forEach(specificIndicator => {
                                const newItem = {
                                    elementId: area.id,
                                    specificIndicatorId: specificIndicator.id,
                                    checkListId: specificIndicator.checkListIndicator.checkListId,
                                    rate: '',
                                    score: 0
                                };
                                newElement.items.push(newItem);
                            });

                            groupedArray.push(newElement);
                        }
                    });
                }


                setFormCompiledData(groupedArray)

            }
            )
            .catch(error => console.error(error));
    }
        
    const getCheckListIndicatorByCheckList = (id) => {
        setCheckListSpecificIndicatorList([]);
        setCheckListIndicatorList([]);
        setCheckListRateList([]);

        fetch(ApiUrl.checkListIndicatorEndpoint + "/GetAllByCheckList/" + id)
            .then(response => response.json())
            .then(json => {
                const filteredCheckListIndicator = json.filter(indicator => !checkListDisabled.includes(indicator.id));
                setCheckListIndicatorList(filteredCheckListIndicator.sort((a, b) => a.item.localeCompare(b.item)));

                const tempSpInPromises = json.map(async indicator => {
                    const response = await fetch(ApiUrl.checkListSpecificIndicatorEndpoint + "/GetAllByCheckListIndicator/" + indicator.id);
                    return await response.json();
                });

                json.map(async indicator => {
                    const response = await fetch(ApiUrl.checkListRateEndpoint + "/GetAllByCheckListIndicator/" + indicator.id);
                    const responseJson = await response.json();
                    const indicatorRate = responseJson.flat();
                    indicatorRate.forEach(element => {
                        checkListRateList.push(element)
                    });
                    handleRateListDuplicates();
                });

                return Promise.all(tempSpInPromises).then(tempSpIn => ({ json, tempSpIn }));
            })
            .then(({ json, tempSpIn }) => {
                const specificIndicator = tempSpIn.flat();
                setCheckListSpecificIndicatorList(specificIndicator.sort((a, b) => a.item.localeCompare(b.item)));

                const tempSpInRatePromises = specificIndicator.map(async spIn => {
                    const response = await fetch(ApiUrl.checkListRateEndpoint + "/GetAllByCheckListSpecificIndicator/" + spIn.id);
                    return await response.json();
                });

                console.log("SpecificIndicator", specificIndicator);

                return Promise.all(tempSpInRatePromises).then(tempSpInRate => ({ json, tempSpIn, tempSpInRate }));
            })
            .then(({ json, tempSpIn, tempSpInRate }) => {
                const specificIndicatorRate = tempSpInRate.flat();
                specificIndicatorRate.forEach(element => {
                    checkListRateList.push(element)
                });
                console.log("Rate", specificIndicatorRate);
                getCheckListCompiledIndicators(id, json.sort((a, b) => a.item.localeCompare(b.item)), tempSpIn.flat().sort((a, b) => a.item.localeCompare(b.item)));
            })
            .then(handleRateListDuplicates)
            .catch(error => {
                console.error(error);
                setLoadingButton(false);
                setOpen(true);
                setTypeAlert("error");
                setMessageAlert("Error with checkListIndicator!");
            });
    }

    const handleRateListDuplicates = () => {
        const tempCheckListRateList = checkListRateList.reduce((acc, current) => {
            const exists = acc.some(item => item.id === current.id);
            return exists ? acc : [...acc, current];
        }, []);
        setCheckListRateList(tempCheckListRateList);
    }

    useEffect(getCheckListsByAudit, [auditId, audit]);
    useEffect(getAuditById, [auditId]);
    useEffect(getCheckListDisabled, [auditId]);
    useEffect(getUsers, [auditId]);

    function getUsers() {
        fetch(ApiUrl.userEndpoint)
            .then(response => response.json())
            .then(json => {
                setUsers(json)
            })
            .catch(error => console.error(error));
    }

    function getCheckListsByAudit() {
        fetch(ApiUrl.auditEndpoint + "/CheckListAudit/" + auditId)
            .then(response => response.json())
            .then(json => {
                const tempInPromises = json.map(async checkListId => {
                    const response = await fetch(ApiUrl.checkListEndpoint + "/" + checkListId);
                    return await response.json();
                });
                return Promise.all(tempInPromises);
            })
            .then(allCheckList => {
                if (audit !== undefined && audit.machine.length > 0) {
                    audit.machine.forEach(_ => {
                        getCheckListCompiledIndicatorsForCount(allCheckList);
                    });
                }
                else if (audit !== undefined && audit.line.length > 1) {
                    audit.line.forEach(_ => {
                        getCheckListCompiledIndicatorsForCount(allCheckList);
                    });
                }
                else if (audit !== undefined && audit.area.length > 1) {
                    audit.area.forEach(_ => {
                        getCheckListCompiledIndicatorsForCount(allCheckList);
                    });
                }
                setCheckListList(allCheckList);
                setLoading(false);
                setLoadingButton(false);
            })
            .catch(error => {
                console.error(error)
                setLoading(false);
                setLoadingButton(false);
                setOpen(true);
                setTypeAlert("error");
                setMessageAlert("Error with checkList!");
            });
    }

    function getAuditCheckListCorrectiveActions(checkListId) {
        fetch(`${ApiUrl.auditEndpoint}/AuditCheckListCorrectiveAction/${auditId}/${checkListId}`)
            .then(response => response.json())
            .then(json => { setAuditCheckListCorrectiveActions(json) })
            .catch(error => console.error(error));
    }

    function getAuditCheckListBestPractices(checkListId) {
        fetch(`${ApiUrl.auditEndpoint}/AuditCheckListBestPractice/${auditId}/${checkListId}`)
            .then(response => response.json())
            .then(json => { 
                setAuditCheckListBestPractices(json)
                setLoadingChecklist(false)
             })
            .catch(error => console.error(error));
    }
    
    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                 <Button style={{ cursor: 'pointer', margin: '0 10px' }} onClick={() => exportToExcel()}>
                    <GetApp style={{marginRight: 10}} />
                    EXPORT
                </Button>
                <Button style={{ cursor: 'pointer', margin: '0 10px', color: '#1ab71a' }} onClick={() => handleFinalNotesModalOpen()}>
                    <ChecklistIcon style={{ marginRight: 10 }} />
                    Add Final Notes
                </Button>
            </GridToolbarContainer>
        );
    }

    const exportToExcel = () => {
        const headers = columns.map(column => column.headerName);
  
        const worksheet = XLSX.utils.json_to_sheet([headers, ...checkListList.map(rowData =>
        columns.map(column => {
            switch (column.field) {
                case 'type':
                    return CheckListType[rowData[column.field]];
                default:
                    return column.valueGetter ? column.valueGetter({ row: rowData }) : rowData[column.field];
            }         
        })
        )], { skipHeader: true });
        
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'AuditCheckList');
        XLSX.writeFile(workbook, 'auditCheckList.xlsx');
    };

    const dialogColumns = [
        {
            field: 'indicatorDescription',
            headerName: 'Indicator',
            headerClassName: 'theme--header',
            width: 350,
            cellClassName: (params) =>
                params.row.isSpecificIndicator ? 'specific-indicator-color' : ''
        },
        {
            field: 'weight',
            headerName: 'Weight %',
            headerClassName: 'theme--header',
            width: 150,
            valueFormatter: (params) => `${params.value} %`,
        },
        {
            field: 'avgRating',
            headerName: 'Rating',
            headerClassName: 'theme--header',
            width: 150,
            cellClassName: (params) =>{
                if (params.value >= 1 && params.value < 3) {
                    return 'background-color-rate-red';
                }

                if (params.value >= 3 && params.value < 4) {
                    return 'background-color-rate-orange';
                }

                if (params.value >= 4) {
                    return 'background-color-rate-green';
                }

                return '';
            },
            renderCell: (params) => {
                if((params.value === 0 || !params.value)) {
                    return (
                        <span style={{color: 'red'}}>
                            {params.row.id !== 'total' && (
                             <>undefined</>
                            )
                            }
                        </span>
                    );
                }

                return  params.value.toFixed(1);
            }

        },
        {
            field: 'avgIndicatorScore',
            headerName: 'Weighted Score',
            headerClassName: 'theme--header',
            width: 200,
            renderCell: (params) => {
                if(params.value === 0 || params.value === null) {
                    return (
                        <span style={{color: 'red'}}>undefined</span>
                    );
                }

                return  params.value.toFixed(2);
            }
        }
    ]

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 100
        },
        {
            field: 'code',
            headerName: 'Code',
            width: 200
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 100,
            renderCell: (params) => CheckListType[params.value]
        },
        {
            field: 'subject',
            headerName: 'Subject',
            width: 200,
            valueGetter: (params) => params.row.subject.name
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 400
        },
        {
            field: 'totalElement',
            headerName: 'Total Element',
            width: 100
        },
        {
            field: 'exclusion',
            headerName: 'Exclusion',
            width: 100
        },
        {
            field: 'indicatorCompleted',
            headerName: 'Indicator Completed',
            width: 200
        },
        {
            field: 'totalScore',
            headerName: 'Total Score (average)',
            width: 200
        },
        {
            field: 'totalIndicatorsRating',
            headerName: 'Total Indicators Rating',
            width: 200,
            align: 'center',
            renderCell: (params) =>
                <IconButton onClick={(e) => {
                    e.stopPropagation();
                    handleOpenDialog(params.row.id);
                }}>
                    <AppsIcon/>
                </IconButton>
        }
    ];

    function getCheckListIndicator(checkListId) {
        setLoadingIndicatorTable(true)
        fetch(ApiUrl.auditEndpoint + `/IndicatorsTable?auditId=${auditId}&checkListId=${checkListId}`)
            .then(response => response.json())
            .then(json => {
                setCheckListIndicatorTable([]);

                const filteredRows = json.filter(row => !row.isSpecificIndicator);

                const totalWeight = filteredRows.reduce((a, row) => a + (row.weight || 0), 0);
                const totalScore = filteredRows.reduce((a, row) => a + (row.avgIndicatorScore || 0), 0);

                const totalRow = {
                    id: 'total',
                    indicatorDescription: 'Total',
                    weight: totalWeight,
                    avgRating: null,
                    avgIndicatorScore: totalScore,
                };

                json.push(totalRow);

                setCheckListIndicatorTable(json);
                setLoadingIndicatorTable(false)
            })
            .catch(error => {
                console.error(error)
                setLoadingIndicatorTable(false);
            });
    }

    const handleOpenDialog = (checkListId) => {
        setOpenDialog(true);
        getCheckListIndicator(checkListId);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };



    const getLenght = () => {
        if (audit.machine.length > 1) {
            return audit.machine.length;
        }
        else if (audit.line.length > 1) {
            return audit.line.length;
        }
        else if (audit.area.length > 1) {
            return audit.area.length;
        } else {
            return 1;
        }

    }

    const getCheckListCompiledIndicatorsForCount = (allCheckList) => {
        allCheckList.forEach(checkList => {
            fetch(ApiUrl.auditEndpoint + "/AuditCompiledIndicatorsCount/" + auditId + "/" + checkList.id + "/" + getLenght())
                .then(response => response.json())
                .then(json => {
                    setCheckListCount(json)
                    console.log('jsonCheckExcl', json)
                    if (json.length > 0) {
                        const indicatorsCountJson = json.reduce((total, item) => total + item.indicatorsCount, 0);

                        const compiledIndicatorsCount = json.reduce((total, item) => total + item.compiledIndicatorsCount, 0);
                        const index = allCheckList.findIndex(tempCheck => tempCheck.id === checkList.id);
                        allCheckList[index].exclusion = json.reduce((total, item) => total + item.excluded, 0);
                        const totalScoreJson = json.reduce((total, item) => total + item.totalScore, 0);
                        let totalScore = 0;
                        let indicatorsCount = 0;

                        if (audit.machine.length === 1) {
                            allCheckList[index].indicatorCompleted = `${compiledIndicatorsCount}/${indicatorsCount}`;
                            totalScore = totalScoreJson;
                        }
                        else if (audit.machine.length > 1) {
                            if (totalScoreJson !== 0) {
                                totalScore = totalScoreJson / (audit.machine.length - allCheckList[index].exclusion);
                            }
                            //if (!isNaN(indicatorsCountJson) && indicatorsCountJson !== 0) {
                            //    indicatorsCount = indicatorsCountJson / (audit.machine.length - allCheckList[index].exclusion)
                            //}
                            if (!isNaN(json[0].indicatorsCount)) {
                                indicatorsCount = json[0].indicatorsCount
                            }
                            allCheckList[index].indicatorCompleted = `${compiledIndicatorsCount}/${indicatorsCount * (audit.machine.length - allCheckList[index].exclusion)}`;
                            
                        }
                        else if (audit.line.length > 1) {
                            if (totalScoreJson !== 0) {
                                totalScore = totalScoreJson / (audit.line.length - allCheckList[index].exclusion);
                            }
                            if (!isNaN(indicatorsCountJson) && indicatorsCountJson !== 0) {
                                indicatorsCount = indicatorsCountJson / (audit.line.length - allCheckList[index].exclusion)
                            }
                            allCheckList[index].indicatorCompleted = `${compiledIndicatorsCount}/${indicatorsCount * (audit.line.length - allCheckList[index].exclusion) }`;
                        }
                        else if (audit.area.length > 1) {
                            if (totalScoreJson !== 0) {
                                totalScore = totalScoreJson / (audit.area.length - allCheckList[index].exclusion);
                            }
                            if (!isNaN(indicatorsCountJson) && indicatorsCountJson !== 0) {
                                indicatorsCount = indicatorsCountJson / (audit.area.length - allCheckList[index].exclusion)
                            }
                            allCheckList[index].indicatorCompleted = `${compiledIndicatorsCount}/${indicatorsCount * (audit.area.length - allCheckList[index].exclusion) }`;
                        }
                        allCheckList[index].totalScore = parseFloat(totalScore).toFixed(2);
                        //if (audit.area.length > 1) {
                        //    allCheckList[index].totalElement = audit.area.length;
                        //}

                        //else if (audit.line.length > 1) {
                        //    allCheckList[index].totalElement = audit.line.length;
                        //}

                        if (audit.machine.length > 1) {
                            allCheckList[index].totalElement = audit.machine.length;
                        }
                        else {
                            allCheckList[index].totalElement = 1;
                        }
                        setCheckListList(allCheckList)
                    }
                });
        });
    }

    const handleCheckListRowSelected = async (row) => {
        setLoadingChecklist(true);
        getCheckListIndicatorByCheckList(row[0])
        getAuditCheckListCorrectiveActions(row[0])
        getAuditCheckListBestPractices(row[0])
        setCheckListIdSelectedRow(row[0]);
    }

    //const handleFormSelectedIndicatorChange = (event, indicatorId) => {
    //    debugger;
    //    const rateObject = {
    //        "elementId": auditButtonSelectedId,
    //        "indicatorId": indicatorId,
    //        "checkListId": checkListIdSelectedRow,
    //        "rateId": event.target.value,
    //        "score": (checkListRateList.filter(rate => rate.id === event.target.value)[0].rate * (checkListIndicatorList.filter(indicator => indicator.id === indicatorId)[0].weight / 100)).toFixed(2),
    //        "initialized": false

    //    };

    //    const selectedIndex = formCompiledData.findIndex(x => x.elementId === auditButtonSelectedId.toString());
    //    if (selectedIndex !== -1) {
    //        const selectedItem = formCompiledData[selectedIndex];
    //        const itemIndex = selectedItem.items.findIndex(x => x.indicatorId === indicatorId);
    //        if (itemIndex !== -1) {
    //            const updatedItems = [...selectedItem.items];
    //            updatedItems[itemIndex] = rateObject;
    //            const updatedData = [...formCompiledData];
    //            updatedData[selectedIndex] = { ...selectedItem, items: updatedItems };
    //            updatedData[selectedIndex].initialized = false;
    //            setFormCompiledData(updatedData);
    //        } else {
    //            const updatedItems = [...selectedItem.items, rateObject];
    //            const updatedData = [...formCompiledData];
    //            updatedData[selectedIndex] = { ...selectedItem, items: updatedItems };
    //            updatedData[selectedIndex].initialized = false;
    //            setFormCompiledData(updatedData);
    //        }
    //    }
    //    else {
    //        setFormCompiledData(prevData => [
    //            ...prevData,
    //            { elementId: auditButtonSelectedId.toString(), items: [rateObject] }
    //        ]);
    //    }
    //}
    const handleFormSelectedIndicatorChange = (event, indicatorId) => {
        const rateValue = event.target.value;
        const rateObject = {
            elementId: auditButtonSelectedId,
            indicatorId: indicatorId,
            checkListId: checkListIdSelectedRow,
            rateId: rateValue,
            score: (checkListRateList.find(rate => rate.id === rateValue)?.rate *
                (checkListIndicatorList.find(indicator => indicator.id === indicatorId)?.weight / 100)).toFixed(2),
            initialized: false
        };

        const updatedData = formCompiledData.map((formItem) => {
            if (formItem.elementId === auditButtonSelectedId.toString()) {
                const updatedItems = formItem.items.map(item =>
                    item.indicatorId === indicatorId ? rateObject : item
                );
                return { ...formItem, items: updatedItems, initialized: false };
            }
            return formItem;
        });

        const elementExists = formCompiledData.some(formItem => formItem.elementId === auditButtonSelectedId.toString());

        if (!elementExists) {
            setFormCompiledData([...formCompiledData, { elementId: auditButtonSelectedId.toString(), items: [rateObject] }]);
        } else {
            setFormCompiledData(updatedData);
        }
    };
    const handleFormSelectedSpecificIndicatorChange = (event, specificIndicatorId) => {
        const rateValue = event.target.value;
        const rateObject = {
            "elementId": auditButtonSelectedId,
            "specificIndicatorId": specificIndicatorId,
            "checkListId": checkListIdSelectedRow,
            "rateId": rateValue,
            "score": (checkListRateList.filter(rate => rate.id === rateValue)[0].rate *
                (checkListSpecificIndicatorList.filter(specificIndicator => specificIndicator.id === specificIndicatorId)[0].weight / 100)).toFixed(2),
            "initialized": false
        };


        const updatedData = formCompiledData.map((formItem) => {
            if (formItem.elementId === auditButtonSelectedId.toString()) {
                const updatedItems = formItem.items.map(item =>
                    item.specificIndicatorId === specificIndicatorId ? rateObject : item
                );
                return { ...formItem, items: updatedItems, initialized: false };
            }
            return formItem;
        });

        const elementExists = formCompiledData.some(formItem => formItem.elementId === auditButtonSelectedId.toString());

        if (!elementExists) {
            setFormCompiledData([...formCompiledData, { elementId: auditButtonSelectedId.toString(), items: [rateObject] }]);
        } else {
            setFormCompiledData(updatedData);
        }
    };

    //const handleFormSelectedSpecificIndicatorChange = (event, specificIndicatorId) => {
    //    const rateObject = {
    //        "elementId": auditButtonSelectedId,
    //        "specificIndicatorId": specificIndicatorId,
    //        "checkListId": checkListIdSelectedRow,
    //        "rateId": event.target.value,
    //        "score": (checkListRateList.filter(rate => rate.id === event.target.value)[0].rate *
    //            (checkListSpecificIndicatorList.filter(specificIndicator => specificIndicator.id === specificIndicatorId)[0].weight / 100)).toFixed(2),
    //        "initialized": false
    //    };

    //    const selectedIndex = formCompiledData.findIndex(x => x.elementId === auditButtonSelectedId.toString());
    //    if (selectedIndex !== -1) {
    //        const selectedItem = formCompiledData[selectedIndex];
    //        const itemIndex = selectedItem.items.findIndex(x => x.specificIndicatorId === specificIndicatorId);
    //        if (itemIndex !== -1) {
    //            const updatedItems = [...selectedItem.items];
    //            updatedItems[itemIndex] = rateObject;
    //            const updatedData = [...formCompiledData];
    //            updatedData[selectedIndex] = { ...selectedItem, items: updatedItems };
    //            updatedData[selectedIndex].initialized = false;
    //            setFormCompiledData(updatedData);
    //        } else {
    //            const updatedItems = [...selectedItem.items, rateObject];
    //            const updatedData = [...formCompiledData];
    //            updatedData[selectedIndex] = { ...selectedItem, items: updatedItems };
    //            updatedData[selectedIndex].initialized = false;
    //            setFormCompiledData(updatedData);
    //        }
    //    }
    //    else {
    //        setFormCompiledData(prevData => [
    //            ...prevData,
    //            { elementId: auditButtonSelectedId.toString(), items: [rateObject] }
    //        ]);
    //    }
    //}

    const reCalculateScore = (elementId) => {
        var specificCompiledData = formCompiledData.filter(x => x.elementId === elementId.toString());
        console.log('specificCompiledData', specificCompiledData)
        checkListIndicatorList.forEach((indicator) => {
            debugger;
            var checkListSpecIndi = checkListSpecificIndicatorList.filter(x => x.checkListIndicator.id === indicator.id);
            if (checkListSpecIndi.length > 0) {
                let total = checkListSpecIndi.reduce((accumulator, current) => accumulator + current.weight, 0);
                let selectedIds = checkListSpecIndi.map(x => x.id);
                let filteredItems = specificCompiledData[0].items.filter(item => selectedIds.includes(item.specificIndicatorId));
                var specificNullScores = filteredItems.filter(x => parseFloat(x.score) === 0);
                specificNullScores.forEach((item) => {
                    var specIndi = checkListSpecificIndicatorList.filter(x => x.id === item.specificIndicatorId);
                    if (specIndi && specIndi.length>0) {
                        var weight = specIndi[0].weight
                        total -= weight;
                    }
                })

                filteredItems.forEach(item => {
                    if (parseFloat(item.score) !== 0) {
                        var specIndi2 = checkListSpecificIndicatorList.filter(x => x.id === item.specificIndicatorId);
                        if (specIndi2 && specIndi2.length > 0) {
                            var weight2 = specIndi2[0].weight;
                            var rate = checkListRateList.filter(rate => rate.id === item.rateId)[0].rate
                            item.score = ((weight2 / (total / 100) / 100) * rate).toFixed(2);

                            const selectedIndex = formCompiledData.findIndex(x => x.elementId === elementId.toString());
                            if (selectedIndex !== -1) {
                                const selectedItem = formCompiledData[selectedIndex];
                                const itemIndex = selectedItem.items.findIndex(x => x.specificIndicatorId === item.specificIndicatorId);
                                if (itemIndex !== -1) {
                                    const updatedItems = [...selectedItem.items];
                                    updatedItems[itemIndex] = item;
                                    const updatedData = [...formCompiledData];
                                    updatedData[selectedIndex] = { ...selectedItem, items: updatedItems };
                                    updatedData[selectedIndex].initialized = false;
                                    setFormCompiledData(updatedData);
                                } else {
                                    const updatedItems = [...selectedItem.items, item];
                                    const updatedData = [...formCompiledData];
                                    updatedData[selectedIndex] = { ...selectedItem, items: updatedItems };
                                    updatedData[selectedIndex].initialized = false;
                                    setFormCompiledData(updatedData);
                                }
                            }
                        }
                    }
                });
                console.log('indicatorIdTotal', indicator.id, total) 

            }
        })
        //console.log('checkListIndicatorList', checkListIndicatorList)
        //console.log('specindList', checkListSpecificIndicatorList)
        //console.log('total', total); 
        //console.log('formCompiledData', formCompiledData)
    };
    const handleCheckListRatingsApiSave = () => {
        console.log('formData', formCompiledData);
        setLoadingButton(true);
        setLoadingChecklist(true);
        setOpen(true);
        const concatenatedItems = formCompiledData.filter(x => x.initialized === false).reduce((accumulator, currentValue) => {
            return accumulator.concat(currentValue.items);
        }, []);

        const object = {
            "auditId": auditId,
            "checkListId": checkListIdSelectedRow,
            "compiledIndicatorsJson": JSON.stringify(concatenatedItems)
        };

        fetch(ApiUrl.auditEndpoint + "/CheckListAudit", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(object),
        })
        .then(_ => getCheckListsByAudit())
        .then(setCheckListIdSelectedRow(checkListIdSelectedRow))
        .then(_ => getCheckListIndicatorByCheckList(checkListIdSelectedRow))
        .then(_ => handleDeleteCorrectiveAction())
        .catch(error => {
            console.log(error)
            setLoadingButton(false);
            setLoadingChecklist(false);
            setOpen(true);
            setTypeAlert("Error");
            setMessageAlert("Error during modified");
        });

        setTypeAlert("success");
        setMessageAlert("Success update");
        setTimeout(() => {
            setLoadingChecklist(false);
            window.location.reload();
        }, 0)



    }

    const handleDeleteCorrectiveAction = () => {
        Promise.all(correctiveActionToDelete.map(action =>
            fetch(ApiUrl.auditEndpoint + "/AuditCheckListCorrectiveActionUpdateDelete", {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(action),
            })
        ))
        .then(() => {
            setCorrectiveActionToDelete([]);
        })
        .catch(error => {
            console.error('Error deleting corrective actions:', error);
            setLoadingButton(false);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error deleting corrective actions!");
        });
        
        setLoadingButton(false);
    }

    const changeSelectedAuditButton = (auditButtonId, value, item) => {
        setAuditButtonSelectedId(auditButtonId);
        setMachineOrAreaCode(value);
        setFormCompiledDataError({})
        setReasonTempValue('')
        if (item === 'area') {
            setSelectedAreaId(auditButtonId)
        }
        if (item === 'line') {
            setSelectedLineId(auditButtonId)
        }
        if (item === 'machine') {
            setSelectedMachineId(auditButtonId)
        }
    }

    const renderAuditButtons = () => {
        if (audit === undefined) {
            return;
        }

        //if (audit.area.length > 1) {
        //    return (
        //        <>
        //            <div style={{ display: 'flex', flexDirection: 'column' }}>
        //                {audit.area.map(area =>
        //                    <Button
        //                        key={area.id}
        //                        variant={area.id === auditButtonSelectedId ? "contained" : "outlined"}
        //                        disableElevation
        //                        style={{ color: `${isDeleted(area.id) ? 'white' : ''}`, backgroundColor: `${isDeleted(area.id) ? '#474444' : ''}`, width: 'calc(100%)', marginRight: '15px', marginBottom: '15px', justifyContent: 'start', borderColor: `${showGreenBorder(area.id) === true && isDeleted(area.id) === false ? 'green' : ''}` }}
        //                        onClick={() => changeSelectedAuditButton(area.id, `${area.name}${area.code ? ` (${area.code})` : ''}`, 'area')}
        //                    >
        //                        <div style={{ width: 'calc(100%)', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
        //                            <div style={{ display: 'flex', fontSize: '0.8rem', lineHeight: '1,1px' }} >
        //                                {`${area.name} ${area.code ? ` (${area.code}) ` : ''}`}
        //                            </div>
        //                            {isDeleted(area.id) === false ?
        //                                <div style={{ display: 'flex', fontSize: '1.5rem', fontWeight: 'bold', justifyContent: 'end', flexDirection: 'column' }} >
        //                                    <div>
        //                                        {`${showScoreOnButtons(area.id)}`}
        //                                    </div>
        //                                    <hr />
        //                                    <div>
        //                                        {`${showIndicatorsCompiledNumber(area.id)}`}
        //                                    </div>
        //                                </div> :
        //                                <div style={{ display: 'flex', fontSize: '1.5rem', fontWeight: 'bold', justifyContent: 'end', flexDirection: 'column' }} >
        //                                    <CancelIcon />
        //                                </div>
        //                            }
        //                        </div>
        //                    </Button>
        //                )}
        //            </div>
        //        </>
        //    )
        //}

        //if (audit.line.length > 1) {
        //    return (
        //        <>
        //            <div style={{ display: 'flex', flexDirection: 'column' }}>
        //                {audit.line.map(line =>
        //                    <Button
        //                        key={line.id}
        //                        variant={line.id === auditButtonSelectedId ? "contained" : "outlined"}
        //                        disableElevation
        //                        style={{ color: `${isDeleted(line.id) ? 'white' : ''}`, backgroundColor: `${isDeleted(line.id) ? '#474444' : ''}`, width: 'calc(100%)', marginRight: '15px', marginBottom: '15px', justifyContent: 'start', borderColor: `${showGreenBorder(line.id) === true && isDeleted(line.id) === false ? 'green' : ''}` }}
        //                        onClick={() => changeSelectedAuditButton(line.id, `${line.name}${line.code ? ` (${line.code})` : ''}`, 'line')}
        //                    >
        //                        <div style={{ width: 'calc(100%)', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
        //                            <div style={{ display: 'flex', fontSize: '0.8rem', lineHeight: '1,1px' }} >
        //                                {`${line.name} ${line.code ? ` (${line.code}) ` : ''}`}
        //                            </div>
        //                            {isDeleted(line.id) === false ?
        //                                <div style={{ display: 'flex', fontSize: '1.5rem', fontWeight: 'bold', justifyContent: 'end', flexDirection: 'column' }} >
        //                                    <div>
        //                                        {`${showScoreOnButtons(line.id)}`}
        //                                    </div>
        //                                    <hr />
        //                                    <div>
        //                                        {`${showIndicatorsCompiledNumber(line.id)}`}
        //                                    </div>
        //                                </div> :
        //                                <div style={{ display: 'flex', fontSize: '1.5rem', fontWeight: 'bold', justifyContent: 'end', flexDirection: 'column' }} >
        //                                    <CancelIcon />
        //                                </div>
        //                            }
        //                        </div>
        //                    </Button>
        //                )}
        //            </div>
        //        </>
        //    )
        //}

        if (audit.machine.length > 0) {
            return (
                <>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {audit.machine.map(machine =>
                            <Button
                                key={machine.id}
                                variant={machine.id === auditButtonSelectedId ? "contained" : "outlined"}
                                disableElevation
                                style={{ color: `${isDeleted(machine.id) ? 'white' : ''}`, backgroundColor: `${isDeleted(machine.id) ? '#474444' : ''}`, width: 'calc(100%)', marginRight: '15px', marginBottom: '15px', justifyContent: 'start', borderColor: `${showGreenBorder(machine.id) === true && isDeleted(machine.id) === false ? 'green' : ''}` }}
                                onClick={() => changeSelectedAuditButton(machine.id, `${machine.name}${machine.code ? ` (${machine.code})` : ''}`, 'machine')}
                            >
                                <div style={{ width: 'calc(100%)', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                                    <div style={{ display: 'flex', fontSize: '0.8rem', lineHeight: '1,1px' }} >
                                        {`${machine.name} ${machine.code ? ` (${machine.code}) ` : ''}`}
                                    </div>
                                    {isDeleted(machine.id) === false ?                                        
                                        <div style={{ display: 'flex', fontSize: '1.5rem', fontWeight: 'bold', justifyContent: 'end', flexDirection: 'column' }} >
                                            <div>
                                                {`${showScoreOnButtons(machine.id)}`}
                                            </div>
                                            <hr />
                                            <div>
                                                {`${showIndicatorsCompiledNumber(machine.id)}`}
                                            </div>
                                        </div> :
                                        <div style={{ display: 'flex', fontSize: '1.5rem', fontWeight: 'bold', justifyContent: 'end', flexDirection: 'column' }} >
                                            <CancelIcon />
                                        </div>
                                    }
                                </div>
                            </Button>
                        )}
                    </div>
                </>
            )
        }

        if (audit.machine.length === 1) {
            return (
                <>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {audit.machine.map(machine =>
                            <Button
                                key={machine.id}
                                variant={machine.id === auditButtonSelectedId ? "contained" : "outlined"}
                                disableElevation
                                style={{ color: `${isDeleted(machine.id) ? 'white' : ''}`, backgroundColor: `${isDeleted(machine.id) ? '#474444' : ''}`, width: 'calc(100%)', marginRight: '15px', marginBottom: '15px', justifyContent: 'start', borderColor: `${showGreenBorder(machine.id) === true && isDeleted(machine.id) === false ? 'green' : ''}` }}
                                onClick={() => changeSelectedAuditButton(machine.id, `${machine.name}${machine.code ? ` (${machine.code})` : ''}`, 'machine')}
                            >
                                <div style={{ width: 'calc(100%)', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                                    <div style={{ display: 'flex', fontSize: '0.8rem', lineHeight: '1,1px' }} >
                                        {`${machine.name} ${machine.code ? ` (${machine.code}) ` : ''}`}
                                    </div>
                                    {isDeleted(machine.id) === false ?
                                        <div style={{ display: 'flex', fontSize: '1.5rem', fontWeight: 'bold', justifyContent: 'end', flexDirection: 'column' }} >
                                            <div>
                                                {`${showScoreOnButtons(machine.id)}`}
                                            </div>
                                            <hr />
                                            <div>
                                                {`${showIndicatorsCompiledNumber(machine.id)}`}
                                            </div>
                                        </div> :
                                        <div style={{ display: 'flex', fontSize: '1.5rem', fontWeight: 'bold', justifyContent: 'end', flexDirection: 'column' }} >
                                            <CancelIcon />
                                        </div>
                                    }
                                </div>
                            </Button>
                        )}
                    </div>
                </>
            )
        }
    }

    const isDeleted = (elementId) => {
        if (formCompiledData.find(x => x.elementId === elementId.toString()) &&
            formCompiledData.find(x => x.elementId === elementId.toString())?.items[0].deleted) {
            return true;
        }
        return false;
    }

    const showIndicatorsCompiledNumber = (elementId) => {
        let x;
        if (checkListCount.length > 0) {
            const data = checkListCount.filter(item => item.elementId === elementId);
            const indicatorsCount = data.reduce((total, item) => total + item.indicatorsCount, 0);
            const compiledIndicatorsCount = data.reduce((total, item) => total + item.compiledIndicatorsCount, 0);
            x = `${compiledIndicatorsCount}/${indicatorsCount}`
        }

        return x;
    }

    const showGreenBorder = (elementId) => {
        if (checkListCount.length > 0) {
            const data = checkListCount.filter(item => item.elementId === elementId);

            const indicatorsCount = data.reduce((total, item) => total + item.indicatorsCount, 0);
            const compiledIndicatorsCount = data.reduce((total, item) => total + item.compiledIndicatorsCount, 0);

            if (indicatorsCount === compiledIndicatorsCount) {
                return true;
            }
        }

        return false;
    }

    const showScoreOnButtons = (elementId) => {
        let sumScore = 0;
        if (elementId) {
            if (auditCheckListSelected.length > 0) {
                const checkListCounted = checkListCount.find(item => item.elementId === elementId);
                if (checkListCounted) {
                    sumScore = parseFloat(checkListCounted.totalScore)
                }
                //const data = auditCheckListSelected.filter(item =>  item.elementId === elementId);
                //const dataFiltered = auditCheckListSelected.filter(item => item.elementId === elementId && !item.indicatorId);

                //const specificIndicatorIds = dataFiltered.map(item => item.specificIndicatorId);
                //const specificIndicatorData = checkListSpecificIndicatorList.filter(item => specificIndicatorIds.includes(item.id));
                //if (data) {
                //    let sumSpecIndi = 0;
                //    let sumPadre = 0;
                //    data.forEach(item => {
                //        if (item.indicatorId) {
                //            sumPadre = parseFloat(item.score)
                //        }
                //    })
                //    if (specificIndicatorData) {
                //        specificIndicatorData.forEach(item => {
                //            const score = parseFloat(dataFiltered.find(x => x.specificIndicatorId === item.id)?.score);
                //            const weight = parseFloat(item.checkListIndicator.weight);
                //            if (!isNaN(score) && !isNaN(weight)) {
                //                sumSpecIndi += parseFloat(score) * parseFloat(item.checkListIndicator.weight / 100)
                //            }
                //        })
                //    }
                //    sumScore += sumSpecIndi;
                //    sumScore += sumPadre 
                //}
            }
        }

        return sumScore.toFixed(2);
    }

    const showScoreForIndicator = (indicatorId) => {
        let score = "";

        if (formCompiledData.find(x => x.elementId === auditButtonSelectedId.toString())?.items.find(x => x.indicatorId === indicatorId) !== undefined &&
            !isNaN(formCompiledData.find(x => x.elementId === auditButtonSelectedId.toString())?.items.find(x => x.indicatorId === indicatorId).score)) {

            score = formCompiledData.find(x => x.elementId === auditButtonSelectedId.toString())?.items.find(x => x.indicatorId === indicatorId).score
        }


        return score;
    }

    const showScoreForSpecificIndicator = (specificIndicatorId) => {
        let score = "";

        if (formCompiledData.find(x => x.elementId === auditButtonSelectedId.toString())?.items.find(x => x.specificIndicatorId === specificIndicatorId) !== undefined &&
            !isNaN(formCompiledData.find(x => x.elementId === auditButtonSelectedId.toString())?.items.find(x => x.specificIndicatorId === specificIndicatorId).score)) {
            score = formCompiledData.find(x => x.elementId === auditButtonSelectedId.toString())?.items.find(x => x.specificIndicatorId === specificIndicatorId).score;
        }

        return score;
    }

    const calculateIndicatorScore = (indicatorId) => {
        const specificIndicatorsIds = checkListSpecificIndicatorList.filter(specificIndicator => specificIndicator.checkListIndicator.id === indicatorId).map(x => x.id)
        
        const specificIndicatorScores = [];

        specificIndicatorsIds.forEach(specInd => {
            if (formCompiledData.find(x => x.elementId === auditButtonSelectedId.toString())?.items.find(x => x.specificIndicatorId === specInd) !== undefined &&
                !isNaN(formCompiledData.find(x => x.elementId === auditButtonSelectedId.toString())?.items.find(x => x.specificIndicatorId === specInd).score)) {
                specificIndicatorScores.push(parseFloat(formCompiledData.find(x => x.elementId === auditButtonSelectedId.toString())?.items.find(x => x.specificIndicatorId === specInd).score));
            }
        })

        return (specificIndicatorScores.reduce((a,v) =>  a = a + v, 0) * (checkListIndicatorList.filter(indicator => indicator.id === indicatorId)[0].weight / 100)).toFixed(2)
    }

    const [formCompiledDataError, setFormCompiledDataError] = useState({});
    const [reasonTempValue, setReasonTempValue] = useState('');
    const [correctiveActionToDelete, setCorrectiveActionToDelete] = useState([]);
    const handleReasonChange = (newValue) => {
        setReasonTempValue(newValue);
    };

    const handleDeleteElement = () => {

        if (reasonTempValue !== '') {
            setFormCompiledDataError({})
            if (formCompiledData.length > 0) {
                const updatedFormCompiledData = formCompiledData.map(item => {
                    if (item.elementId === auditButtonSelectedId.toString()) {
                        const updatedItems = item.items.map(subItem => ({
                            ...subItem,
                            deleted: reasonTempValue
                        }));
                        return {
                            ...item,
                            initialized : false,
                            items: updatedItems
                        };
                    }
                    return item;
                });
                setFormCompiledData(updatedFormCompiledData);

            } else {
                formCompiledData.push({
                    elementId: auditButtonSelectedId.toString(),
                    initialized: false,
                    items: [{
                        elementId: auditButtonSelectedId.toString(),
                        deleted: reasonTempValue
                    }]
                });
            }
            const existsIndex = correctiveActionToDelete.findIndex(action =>
                action.machineOrAreaCode === machineOrAreaCode &&
                action.auditId === auditId &&
                action.checkListId === checkListIdSelectedRow
            );

            if (existsIndex !== -1) {
                setCorrectiveActionToDelete(prevState => {
                    const updatedActions = [...prevState];
                    updatedActions[existsIndex] = {
                        ...updatedActions[existsIndex],
                        deleted: true
                    };
                    return updatedActions;
                });
            } else {
                setCorrectiveActionToDelete(prevState => [
                    ...prevState,
                    {
                        asset: machineOrAreaCode,
                        auditId: auditId,
                        checkListId: checkListIdSelectedRow,
                        deleted: true
                    }
                ]);
            }

        } else {
            setFormCompiledDataError(prevState => ({
                ...prevState,
                [auditButtonSelectedId]: true
            }));
        }
    }

    const handleRestoreElement = () => {
        setFormCompiledDataError({});
        setReasonTempValue('');

        const updatedFormCompiledData = formCompiledData.map(item => {
            if (item.elementId === auditButtonSelectedId.toString()) {
                const updatedItems = item.items.map(subItem => {
                    const { deleted, ...rest } = subItem; 
                    return rest;
                });
                return {
                    ...item,
                    initialized: false,
                    items: updatedItems
                };
            }
            return item;
        });

        setFormCompiledData(updatedFormCompiledData);

        const existsIndex = correctiveActionToDelete.findIndex(action =>
            action.machineOrAreaCode === machineOrAreaCode &&
            action.auditId === auditId &&
            action.checkListId === checkListIdSelectedRow
        );

        if (existsIndex !== -1) {
            setCorrectiveActionToDelete(prevState => {
                const updatedActions = [...prevState];
                updatedActions[existsIndex] = {
                    ...updatedActions[existsIndex],
                    deleted: false
                };
                return updatedActions;
            });
        } else {
            setCorrectiveActionToDelete(prevState => [
                ...prevState,
                {
                    asset: machineOrAreaCode,
                    auditId: auditId,
                    checkListId: checkListIdSelectedRow,
                    deleted: false
                }
            ]);
        }

    }

    function getMissingIndicatorIds(items, indicatorIds) {
        const existingIndicatorIds = new Set();

        items.forEach(item => {
            existingIndicatorIds.add(item.indicatorId);
        });

        const missingIndicatorIds = indicatorIds.filter(id => !existingIndicatorIds.has(id));
        return missingIndicatorIds;
    }

    const showAverageScoreForIndicator = (id) => {
        const indicatorsId = checkListIndicatorList.map(x => x.id)
        const indicatorsIdwithSpecificIndicators = getMissingIndicatorIds(formCompiledData[0].items, indicatorsId);

        let totalScore = 0;
        let count = 0;
        formCompiledData.forEach(element => {
            element.items.forEach(item => {
                if (item.indicatorId === id && item.score !== "" && item.score !== null && !item.deleted) {
                    totalScore += parseFloat(item.score);
                    count++;
                } 
               
            });
        });
        if (indicatorsIdwithSpecificIndicators.includes(id)) {
            const weight = checkListIndicatorList.find(x => x.id === id).weight;
            const specificIndicatorsIds = checkListSpecificIndicatorList.filter(specificIndicator => specificIndicator.checkListIndicator.id === id).map(x => x.id);

            let sum = 0
            specificIndicatorsIds.forEach(spec => {
                sum += parseFloat(showAverageScoreForSpecificIndicator(spec))
            })

            totalScore = parseFloat(sum * (weight / 100));

            count = 1
        }
        const averageScore = count > 0 ? totalScore / count : 0;
        return averageScore.toFixed(2)
    };

    const showAverageScoreForSpecificIndicator = (id) => {
        let totalScore = 0;
        let count = 0;
        formCompiledData.forEach(element => {
            element.items.forEach(item => {
                if (item.specificIndicatorId === id && item.score !== "" && item.score !== null && !item.deleted) {
                    totalScore += parseFloat(item.score);
                    count++;
                }
            });
        });

        const averageScore = count > 0 ? totalScore / count : 0;
        return averageScore.toFixed(2)
    }

    const showCorrectiveActionsIndicatorCount = (id) => {
        let count = 0;
        const correctiveActions = auditCheckListCorrectiveActions.filter(x => x.indicatorId === id && x.deleted === false);
        if (correctiveActions) {
            count = correctiveActions.length;
        }

        return count;
    }

    const showCorrectiveActionsSpecificIndicatorCount = (id, indicatorId) => {
        let count = 0
        const correctiveActions = auditCheckListCorrectiveActions.filter(x => x.specificIndicatorId === id);
        if (correctiveActions) {
            count = correctiveActions.length;
        }

        return count;
    }

    const handleCloseSnackbar = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const [isFinalNotesOpened, setIsFinalNotesOpened] = useState(false);
    const [formFinalNotesData, setFormFinalNotesData] = useState([{}]);
    const [finalNotesErrors, setFinalNotesErrors] = useState({});

    const handleFinalNotesModalOpen = () => {
        setFinalNotesErrors({});
        setIsFinalNotesOpened(true);
    }
    const handleFinalNotesClose = () => {
        setFormFinalNotesData({
            id: audit.id,
            notes: audit.finalNotes
        });
        setIsFinalNotesOpened(false)
        setFinalNotesErrors({});
    }

    const handleFNChange = (event) => {
        const { name, value } = event.target;
        setFormFinalNotesData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const handleFinalNotesSubmit = () => {
        const newErrors = {};
        if (formFinalNotesData.notes.trim() === '') {
            newErrors.notes = 'Notes is required';
        }
        setFinalNotesErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            return;
        }
        console.log('formFinalNotesData', formFinalNotesData)
        
        fetch(ApiUrl.auditEndpoint + "/AuditFinalNotes", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formFinalNotesData),
        })
        .then(response => response.json())
        .then(() => {
            setIsFinalNotesOpened(false);
        })
        .catch(error => {
            console.error('Error submitting final notes:', error);
        });
    }

    const getRateId = (indicatorType, isSpecificIndicator) => {
        let item;
        if (!isSpecificIndicator)
        {
            const indicator = indicatorType;
            item = formCompiledData.find(x => x.elementId === auditButtonSelectedId.toString())?.items.find(x => x.indicatorId === indicator.id);

            return item?.rateId || '';

        }else{
            const specificIndicator = indicatorType;
            item = formCompiledData.find(x => x.elementId === auditButtonSelectedId.toString())?.items.find(x => x.specificIndicatorId === specificIndicator.id);

            return  item?.rateId || '';
        }
    }

    const disableSelect = (indicatorType, isSpecificIndicator) => {
        let item;

        if (!isSpecificIndicator)
        {
            const indicator = indicatorType;
            item = formCompiledData.find(x => x.elementId === auditButtonSelectedId.toString())?.items.find(x => x.indicatorId === indicator.id);
            console.log("Item: ", item)
            console.log("ValueDisable: ", (item && item.deleted) || readOnly === 'true')

            return !!((item && item.deleted) || readOnly === 'true');

        }else{
            const specificIndicator = indicatorType;
            item = formCompiledData.find(x => x.elementId === auditButtonSelectedId.toString())?.items.find(x => x.specificIndicatorId === specificIndicator.id);

            return !!((item && item.deleted) || readOnly === 'true');
        }
    }

    return (
        <>
            <Box>
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={4000} onClose={handleCloseSnackbar}>
                    <Alert severity={typeAlert}>
                        <span>{messageAlert}</span>
                    </Alert>
                </Snackbar>
                <Box display="flex" alignItems="center" style={{ marginBottom: 20 }}>
                    <Button variant="contained" disableElevation onClick={navigateBack}>
                        <ArrowBackIcon />
                    </Button>
                    <Typography variant="h4" style={{ marginLeft: 30 }}>{readOnly === 'true' ? 'View' : 'Edit' } Audit's CheckList</Typography>
                </Box>
                {loading ? (
                    <>
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                    </>
                ) : (
                    checkListList.length === 0 ?
                        <Typography variant="h6" style={{ marginRight: 30 }}>No CheckList for this Audit</Typography> :
                        <DataGrid
                            style={{ backgroundColor: appColor.white, marginBottom: 30 }}
                            rows={checkListList}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5
                                    },
                                },
                            }}
                            onRowSelectionModelChange={(newRowSelectionModel) => {
                                handleCheckListRowSelected(newRowSelectionModel);
                            }}
                            slots={{
                                toolbar: CustomToolbar,
                            }}
                            pageSizeOptions={[5]}
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                        />
                )
                }

                {loadingChecklist ? (
                    <>
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                    </>
                ) : (
                    checkListIdSelectedRow === 0 ? '' :
                        <Grid container spacing={3}>
                            <Grid item md={2} style={{ height: '625px', overflowY: 'scroll' }}>
                                {renderAuditButtons()}
                                {readOnly === 'true' ?
                                    <Button key={"all-checklist"} variant={auditButtonSelectedId === 0 ? "contained" : "outlined"} disableElevation style={{ width: 'calc(100%)', marginRight: '15px', marginBottom: '15px', justifyContent: 'start' }} onClick={() => changeSelectedAuditButton(0)}>
                                        All CheckList
                                    </Button> : <></>
                                }
                            </Grid>
                            <Grid item md={10} style={{ height: '700px', overflowY: 'scroll' }}>
                                <Grid item style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: "5px", }}>
                                    {readOnly === 'false' && (
                                        <Grid item md={4} style={{ padding: '10px', alignItems: 'center', display: 'flex'}}>
                                            <Button variant="contained" color="info" disableElevation onClick={() => reCalculateScore(auditButtonSelectedId)} style={{ marginRight: 15 }}><RestoreIcon style={{ marginRight: 15 }} />
                                                Refresh scores
                                            </Button>
                                        </Grid>
                                    )}
                                    <Grid item md={6}>
                                        <Paper elevation={0} style={{ border: "1px solid lightgray", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            {formCompiledData.find(x => x.elementId === auditButtonSelectedId.toString())?.items &&
                                            formCompiledData.find(x => x.elementId === auditButtonSelectedId.toString())?.items[0].deleted?
                                                <>
                                                    <Grid item md={2} style={{ justifyContent: 'center', display: 'flex' }}>
                                                        {readOnly === 'false' ?
                                                            <Tooltip placement="bottom" title="Restore element" arrow>
                                                                <RestoreIcon style={{ cursor: 'pointer', fontSize: '2em' }} onClick={handleRestoreElement} />
                                                            </Tooltip>
                                                            :
                                                            <Tooltip placement="bottom" title="Restore element" arrow>
                                                                <RestoreIcon style={{ cursor: 'not-allowed', fontSize: '2em' }} />
                                                            </Tooltip>
                                                        }
                                                    </Grid>
                                                    <Grid item md={10} style={{ padding: '10px', alignItems: 'center', display: 'flex' }}>
                                                        <Grid item md={5}>
                                                    <span style={{ fontSize: '1em', fontWeight: 'bold' }}>
                                                        REASON FOR EXCLUSION:
                                                    </span>
                                                        </Grid>
                                                        <Grid item md={5}>
                                                    <span style={{ fontSize: '1em' }}>
                                                        {formCompiledData.find(x => x.elementId === auditButtonSelectedId.toString())?.items &&
                                                        formCompiledData.find(x => x.elementId === auditButtonSelectedId.toString())?.items[0].deleted ?
                                                            formCompiledData.find(x => x.elementId === auditButtonSelectedId.toString())?.items[0].deleted : ''}
                                                    </span>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                                :<>
                                                    {readOnly === 'false' ?
                                                        <>
                                                            <Grid item md={2} style={{ justifyContent: 'center', display: 'flex' }}>
                                                                <Tooltip placement="bottom" title="Disable element" arrow>
                                                                    <DeleteIcon style={{ fontSize: '2em', cursor: 'pointer' }} onClick={handleDeleteElement}/>
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid item md={10} style={{ paddingRight: '5px' }}>
                                                                <FormControl size="small" fullWidth>
                                                                    <TextField
                                                                        label="Reason for exclusion"
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        helperText={formCompiledDataError[auditButtonSelectedId] ? "Please insert a reason" : ""}
                                                                        error={formCompiledDataError[auditButtonSelectedId]}
                                                                        value={reasonTempValue}
                                                                        onChange={(event) => handleReasonChange(event.target.value)}
                                                                        fullWidth
                                                                        InputProps={{ style: { height: '60px' } }}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        </>
                                                        :
                                                        <></>
                                                    }

                                                </>
                                            }
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <Paper elevation={0} style={{ padding: "15px", border: "1px solid lightgray" }}>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>N</TableCell>
                                                    <TableCell>Indicator</TableCell>
                                                    <TableCell>Weighting (%)</TableCell>
                                                    <TableCell>Rating</TableCell>
                                                    <TableCell>Score</TableCell>
                                                    <TableCell>Corrective Action</TableCell>
                                                    <TableCell>B.P Proposal</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {auditButtonSelectedId !== 0 ?
                                                <TableBody>
                                                    {checkListIndicatorList.map((indicator) =>
                                                        <React.Fragment key={`fr${indicator.id}`}>
                                                            <TableRow key={indicator.id} style={{ backgroundColor: '#DDDDDD' }}>
                                                                <TableCell style={{ width: 100, fontWeight: 'bold' }}>{indicator.item}</TableCell>
                                                                <TableCell style={{ width: 250, fontWeight: 'bold' }}>{indicator.description}</TableCell>
                                                                <TableCell style={{ width: 150, fontWeight: 'bold' }}>{indicator.weight}%</TableCell>
                                                                <TableCell style={{ width: 400, fontWeight: 'bold' }}>
                                                                    {checkListRateList.filter(rate => rate.checkListIndicator !== undefined && rate.checkListIndicator.id === indicator.id).length > 0 ?
                                                                        <FormControl size="small" fullWidth>
                                                                            <Select
                                                                                disabled={disableSelect(indicator, false)
                                                                                /*(formCompiledData.find(x => x.elementId === auditButtonSelectedId.toString())?.items.find(x => x.indicatorId === indicator.id) &&
                                                                                    formCompiledData.find(x => x.elementId === auditButtonSelectedId.toString())?.items.find(x => x.indicatorId === indicator.id).deleted) || readOnly === 'true' ?
                                                                                    true : false*/}
                                                                                value={getRateId(indicator, false)}
                                                                                onChange={(event) => handleFormSelectedIndicatorChange(event, indicator.id)}
                                                                            >
                                                                                {checkListRateList.filter(rate => rate.checkListIndicator !== undefined && rate.checkListIndicator.id === indicator.id)
                                                                                    .sort((a, b) => a.rate - b.rate)
                                                                                    .map(rate => <MenuItem key={rate.id} value={rate.id}>{rate.rate} - {rate.description}</MenuItem>)}
                                                                            </Select>
                                                                        </FormControl>
                                                                        : ""}
                                                                </TableCell>
                                                                <TableCell style={{ width: 150, fontWeight: 'bold' }}>
                                                                    {checkListRateList.filter(rate => rate.checkListIndicator !== undefined && rate.checkListIndicator.id === indicator.id).length > 0 ?
                                                                        showScoreForIndicator(indicator.id) : calculateIndicatorScore(indicator.id)}
                                                                </TableCell>
                                                                <TableCell style={{ width: 150, fontWeight: 'bold' }}>
                                                                    {hasCorrectiveAction(indicator, 'indicator') ?
                                                                        <>
                                                                            {(formCompiledData.find(x => x.elementId === auditButtonSelectedId.toString())?.items[0].deleted !== undefined) || readOnly === 'true' ?
                                                                                <>
                                                                                    <Tooltip title="View Corrective Action Details" arrow>
                                                                                        <TaskAltIcon onClick={() => handleCompileModalOpenViewOnly(indicator, 'indicator')} style={{ cursor: 'pointer', marginLeft: 15, color: getCheckColor(indicator, 'indicator') }} />
                                                                                    </Tooltip>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <Tooltip title="Edit Corrective Action" arrow>
                                                                                        {getCheckColor(indicator, 'indicator') === '#2e7d32' ?
                                                                                            <EngineeringIcon style={{ cursor: 'not-allowed', marginLeft: 15 }} />
                                                                                            :
                                                                                            <EngineeringIcon onClick={() => handleCompileModalOpen(indicator, 'indicator')} style={{ cursor: 'pointer', marginLeft: 15 }} />
                                                                                        }
                                                                                    </Tooltip>
                                                                                    <Tooltip title="View Corrective Action Details" arrow>
                                                                                        <TaskAltIcon onClick={() => handleCompileModalOpenViewOnly(indicator, 'indicator')} style={{ cursor: 'pointer', marginLeft: 15, color: getCheckColor(indicator, 'indicator') }} />
                                                                                    </Tooltip>
                                                                                </>
                                                                            }

                                                                        </>
                                                                        :
                                                                        <>
                                                                            {readOnly === 'false' && (formCompiledData.find(x => x.elementId === auditButtonSelectedId.toString())?.items[0].deleted === undefined) ?
                                                                                <Tooltip title="Add Corrective Action" arrow>
                                                                                    <EngineeringIcon onClick={() => handleCompileModalOpen(indicator, 'indicator')} style={{ cursor: 'pointer', marginLeft: 15 }} />
                                                                                </Tooltip>
                                                                                :
                                                                                <></>
                                                                            }
                                                                        </>
                                                                    }
                                                                </TableCell>
                                                                <TableCell style={{ width: 150, fontWeight: 'bold' }}>
                                                                    {hasBestPractice(indicator, 'indicator') ?
                                                                        <>
                                                                            <Tooltip title="View Best Practice Proposal" arrow>
                                                                                <LoginIcon onClick={() => handleBestPracticeModalOpen(indicator, 'indicator')} style={{ cursor: 'pointer', marginLeft: 15 }} />
                                                                            </Tooltip>
                                                                            <Tooltip title="View Best Practice Proposal" arrow>
                                                                                <TaskAltIcon onClick={() => handleBestPracticeModalOpen(indicator, 'indicator')} style={{ cursor: 'pointer', marginLeft: 15, color: '#2e7d32' }} />
                                                                            </Tooltip>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {readOnly === 'false' && (
                                                                                <Tooltip title="Add Best Practice Proposal" arrow>
                                                                                    <LoginIcon onClick={() => handleBestPracticeModalOpen(indicator, 'indicator')} style={{ cursor: 'pointer', marginLeft: 15 }} />
                                                                                </Tooltip>
                                                                            ) }
                                                                        </>
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                            {checkListSpecificIndicatorList
                                                                .filter(specificIndicator => specificIndicator.checkListIndicator.id === indicator.id)
                                                                .map((specificIndicator) =>
                                                                    <TableRow key={specificIndicator.id} style={{ backgroundColor: '#EEEEEE' }}>
                                                                        <TableCell style={{ width: 100 }}>{specificIndicator.item}</TableCell>
                                                                        <TableCell style={{ width: 250 }}>{specificIndicator.description}</TableCell>
                                                                        <TableCell style={{ width: 150 }}>{specificIndicator.weight}%</TableCell>
                                                                        <TableCell style={{ width: 400 }}>
                                                                            <FormControl size="small" fullWidth>
                                                                                <Select
                                                                                    disabled={disableSelect(indicator, true)
                                                                                    /*(formCompiledData.find(x => x.elementId === auditButtonSelectedId.toString())?.items.find(x => x.specificIndicatorId === specificIndicator.id) &&
                                                                                        formCompiledData.find(x => x.elementId === auditButtonSelectedId.toString())?.items.find(x => x.specificIndicatorId === specificIndicator.id).deleted) || readOnly === 'true' ?
                                                                                        true : false*/}
                                                                                    value={getRateId(specificIndicator, true)}
                                                                                    onChange={(event) => handleFormSelectedSpecificIndicatorChange(event, specificIndicator.id)}
                                                                                >
                                                                                    {checkListRateList.filter(rate => rate.checkListSpecificIndicator !== undefined && rate.checkListSpecificIndicator.id === specificIndicator.id)
                                                                                        .sort((a,b) => a.rate - b.rate)
                                                                                        .map(rate => <MenuItem key={rate.id} value={rate.id}>{rate.rate} - {rate.description}</MenuItem>)}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </TableCell>
                                                                        <TableCell style={{ width: 150 }}>
                                                                            {showScoreForSpecificIndicator(specificIndicator.id)}
                                                                        </TableCell>
                                                                        <TableCell style={{ width: 150, fontWeight: 'bold' }}>
                                                                            {hasCorrectiveAction(specificIndicator, 'specificIndicator') ?
                                                                                <>
                                                                                    {(formCompiledData.find(x => x.elementId === auditButtonSelectedId.toString())?.items[0].deleted !== undefined) || readOnly === 'true' ?
                                                                                        <>
                                                                                            <Tooltip title="View Corrective Action Details" arrow>
                                                                                                <TaskAltIcon onClick={() => handleCompileModalOpenViewOnly(specificIndicator, 'specificIndicator')} style={{ cursor: 'pointer', marginLeft: 15, color: getCheckColor(specificIndicator, 'specificIndicator') }} />
                                                                                            </Tooltip>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <Tooltip title="Edit Corrective Action" arrow>
                                                                                                {getCheckColor(specificIndicator, 'specificIndicator') === '#2e7d32' ?
                                                                                                    <EngineeringIcon style={{ cursor: 'not-allowed', marginLeft: 15 }} />
                                                                                                    :
                                                                                                    <EngineeringIcon onClick={() => handleCompileModalOpen(specificIndicator, 'specificIndicator')} style={{ cursor: 'pointer', marginLeft: 15 }} />
                                                                                                }
                                                                                            </Tooltip>
                                                                                            <Tooltip title="View Corrective Action Details" arrow>
                                                                                                <TaskAltIcon onClick={() => handleCompileModalOpenViewOnly(specificIndicator, 'specificIndicator')} style={{ cursor: 'pointer', marginLeft: 15, color: getCheckColor(specificIndicator, 'specificIndicator') }} />
                                                                                            </Tooltip>
                                                                                        </>

                                                                                    }

                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {readOnly === 'false' && (formCompiledData.find(x => x.elementId === auditButtonSelectedId.toString())?.items[0].deleted === undefined) ?
                                                                                        <Tooltip title="Add Corrective Action" arrow>
                                                                                            <EngineeringIcon onClick={() => handleCompileModalOpen(specificIndicator, 'specificIndicator')} style={{ cursor: 'pointer', marginLeft: 15 }} />
                                                                                        </Tooltip>
                                                                                        : <></>
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell style={{ width: 150, fontWeight: 'bold' }}>
                                                                            {hasBestPractice(specificIndicator, 'specificIndicator') ?
                                                                                <>

                                                                                    <Tooltip title="View Best Practice Proposal" arrow>
                                                                                        <LoginIcon onClick={() => handleBestPracticeModalOpen(specificIndicator, 'specificIndicator')} style={{ cursor: 'pointer', marginLeft: 15 }} />
                                                                                    </Tooltip>
                                                                                    <Tooltip title="View Best Practice Proposal" arrow>
                                                                                        <TaskAltIcon onClick={() => handleBestPracticeModalOpen(specificIndicator, 'specificIndicator')} style={{ cursor: 'pointer', marginLeft: 15, color: '#2e7d32' }} />
                                                                                    </Tooltip>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {readOnly === 'false' && (
                                                                                        <Tooltip title="Add Best Practice Proposal" arrow>
                                                                                            <LoginIcon onClick={() => handleBestPracticeModalOpen(specificIndicator, 'specificIndicator')} style={{ cursor: 'pointer', marginLeft: 15 }} />
                                                                                        </Tooltip>
                                                                                    )}
                                                                                </>
                                                                            }
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            }
                                                        </React.Fragment>
                                                    )}
                                                </TableBody>
                                                :
                                                <TableBody>
                                                    {checkListIndicatorList.map((indicator) =>
                                                        <React.Fragment key={`fr${indicator.id}`}>
                                                            <TableRow key={indicator.id} style={{ backgroundColor: '#DDDDDD' }}>
                                                                <TableCell style={{ width: 100, fontWeight: 'bold' }}>{indicator.item}</TableCell>
                                                                <TableCell style={{ width: 250, fontWeight: 'bold' }}>{indicator.description}</TableCell>
                                                                <TableCell style={{ width: 150, fontWeight: 'bold' }}>{indicator.weight}%</TableCell>
                                                                <TableCell style={{ width: 400, fontWeight: 'bold' }}></TableCell>
                                                                <TableCell style={{ width: 150, fontWeight: 'bold' }}>
                                                                    {showAverageScoreForIndicator(indicator.id)}
                                                                </TableCell>
                                                                <TableCell style={{ width: 150, fontWeight: 'bold' }}>{showCorrectiveActionsIndicatorCount(indicator.id)}</TableCell>
                                                            </TableRow>
                                                            {checkListSpecificIndicatorList
                                                                .filter(specificIndicator => specificIndicator.checkListIndicator.id === indicator.id)
                                                                .map((specificIndicator) =>
                                                                    <TableRow key={specificIndicator.id} style={{ backgroundColor: '#EEEEEE' }}>
                                                                        <TableCell style={{ width: 100 }}>{specificIndicator.item}</TableCell>
                                                                        <TableCell style={{ width: 250 }}>{specificIndicator.description}</TableCell>
                                                                        <TableCell style={{ width: 150 }}>{specificIndicator.weight}%</TableCell>
                                                                        <TableCell style={{ width: 400 }}></TableCell>
                                                                        <TableCell style={{ width: 150 }}>
                                                                            {showAverageScoreForSpecificIndicator(specificIndicator.id)}
                                                                        </TableCell>
                                                                        <TableCell style={{ width: 150, fontWeight: 'bold' }}>{showCorrectiveActionsSpecificIndicatorCount(specificIndicator.id, indicator.id)}</TableCell>
                                                                    </TableRow>
                                                                )
                                                            }
                                                        </React.Fragment>
                                                    )}
                                                </TableBody>
                                            }
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>
                        </Grid>
                )}

                <Modal
                    open={isCompileModalOpen}
                    onClose={handleCompileModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Typography style={{ color: '#1976d2', fontWeight: 'bold'}} id="modal-modal-title" variant="h5" component="h5">{checkListSelected ? checkListSelected.description : ''}</Typography>
                            <Typography style={{ color: '#1976d2', fontWeight: 'bold'}} id="modal-modal-title" variant="h5" component="h5">Code: {machineOrAreaCode}</Typography>
                        </Box>
                        <Typography style={{ color: '#1976d2' }} id="modal-modal-title" variant="h6" component="h6">{indiCompileSelected ? `${indiCompileSelected.item} - ${indiCompileSelected.description}` : ''}</Typography>

                        <Box marginTop={3} display="flex" alignItems="center">
                            <Box display="flex" alignItems="center" flexWrap="wrap">
                                <Box display="flex" alignItems="center">
                                    <Box>
                                        <TextField
                                            label="Lead Auditor"
                                            variant="outlined"
                                            margin="normal"
                                            disabled
                                            style={{ width: 325 }}
                                            fullWidth
                                            value={audit ? audit.leadAuditor.email : ''}
                                            onChange={handleChange}
                                        />
                                    </Box>
                                    <Box marginLeft={3} paddingTop={1}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Date"
                                                readOnly={viewOnly}
                                                value={formData.date}
                                                onChange={(date) => handleDateChange(date, 'date')}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                </Box>
                                <Box>
                                    <TextField
                                        label="Auditor Remarks"
                                        variant="outlined"
                                        margin="normal"
                                        style={{ width: 570 }}
                                        name="auditorRemarks"
                                        value={formData.auditorRemarks}
                                        onChange={handleChange}
                                        disabled={viewOnly}
                                        fullWidth
                                        multiline
                                        maxRows={4}
                                        error={Boolean(errors.auditorRemarks)}
                                        helperText={errors.auditorRemarks}
                                    />
                                </Box>
                            </Box>
                            <Box display="flex">
                                <Box marginTop={3} marginLeft={3} display="flex" alignItems="center">
                                    <Button
                                        style={buttonStyle}
                                        role={undefined}
                                        component="label"
                                        disabled={viewOnly}
                                        tabIndex={-1}
                                    >
                                        {formData.images.image1 !== null ?
                                            <img src={formData.images.image1} alt='' style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}/>
                                            : <AddAPhotoIcon style={{ fontSize: '3rem' }} />
                                        }

                                        <VisuallyHiddenInput accept="image/*" id={`image-upload-1`} type="file" onChange={(event) => handleImageChange(event, 1)} />
                                    </Button>

                                </Box>
                                <Box marginTop={3} marginLeft={3} display="flex" alignItems="center">
                                    <Button
                                        style={buttonStyle}
                                        role={undefined}
                                        disabled={viewOnly}
                                        component="label"
                                        tabIndex={-1}
                                    >
                                        {formData.images.image2 !== null ?
                                            <img src={formData.images.image2} alt='' style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                                            : <AddAPhotoIcon style={{ fontSize: '3rem' }} />
                                        }
                                        <VisuallyHiddenInput accept="image/*" id={`image-upload-1`} type="file" onChange={(event) => handleImageChange(event, 2)} />
                                    </Button>

                                </Box>
                                <Box marginTop={3} marginLeft={3} display="flex" alignItems="center">
                                    <Button
                                        style={buttonStyle}
                                        role={undefined}
                                        disabled={viewOnly}
                                        component="label"
                                        tabIndex={-1}
                                    >
                                        {formData.images.image3 !== null ?
                                            <img src={formData.images.image3} alt='' style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                                            : <AddAPhotoIcon style={{ fontSize: '3rem' }} />
                                        }
                                        <VisuallyHiddenInput accept="image/*" id={`image-upload-1`} type="file" onChange={(event) => handleImageChange(event, 3)} />
                                    </Button>

                                </Box>
                                <Box marginTop={3} marginLeft={3} display="flex" alignItems="center">
                                    <Button
                                        style={buttonStyle}
                                        role={undefined}
                                        disabled={viewOnly}
                                        component="label"
                                        tabIndex={-1}
                                    >
                                        {formData.images.image4 !== null ?
                                            <img src={formData.images.image4} alt='' style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                                            : <AddAPhotoIcon style={{ fontSize: '3rem' }} />
                                        }
                                        <VisuallyHiddenInput accept="image/*" id={`image-upload-1`} type="file" onChange={(event) => handleImageChange(event, 4)} />
                                    </Button>

                                </Box>
                                <Box marginTop={3} marginLeft={3} display="flex" alignItems="center">
                                    <Button
                                        style={buttonStyle}
                                        role={undefined}
                                        disabled={viewOnly}
                                        component="label"
                                        tabIndex={-1}
                                    >
                                        {formData.images.image5 !== null ?
                                            <img src={formData.images.image5} alt='' style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                                            : <AddAPhotoIcon style={{ fontSize: '3rem' }} />
                                        }
                                        <VisuallyHiddenInput accept="image/*" id={`image-upload-1`} type="file" onChange={(event) => handleImageChange(event, 5)} />
                                    </Button>

                                </Box>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Box>
                                <TextField
                                    label="Action Requested By Auditor (syntetic)"
                                    variant="outlined"
                                    margin="normal"
                                    name="actionRequestedByAuditor"
                                    value={formData.actionRequestedByAuditor}
                                    disabled={viewOnly}
                                    onChange={handleChange}
                                    style={{ width: 570 }}
                                    fullWidth
                                    error={Boolean(errors.actionRequestedByAuditor)}
                                    helperText={errors.actionRequestedByAuditor}
                                />
                            </Box>
                            <Box marginLeft={3}>
                                <InputLabel style={{ fontSize: '0.7rem' }} id="demo-select-small-label">Responsible</InputLabel>
                                <Select
                                    style={{ width: 325 }}
                                    fullWidth
                                    value={formData.responsabile}
                                    name="responsabile"
                                    onChange={handleChange}
                                    error={Boolean(errors.responsabile)}
                                    readOnly={viewOnly}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {users.map((user) => <MenuItem key={user.id} value={user.email}>{user.email}</MenuItem>)}
                                </Select>
                                {Boolean(errors.responsabile) && (
                                    <p className={'MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root'}>
                                        {errors.responsabile}
                                    </p>
                                )}
                            </Box>
                            <Box marginLeft={3} paddingTop={2}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Due Date"
                                        style={{ width: 200 }}
                                        value={formData.dueDate}
                                        onChange={(date) => handleDateChange(date, 'dueDate')}
                                        readOnly={viewOnly}
                                    />
                                    {Boolean(errors.dueDate) && (
                                        <p className={'MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root'}>
                                            {errors.dueDate }
                                        </p>
                                    ) }
                                </LocalizationProvider>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center">

                            <Box>
                                <TextField
                                    label="Reply of responsible on action"
                                    variant="outlined"
                                    margin="normal"
                                    style={{ width: 325 }}
                                    value={formData.replyOfResponsibleOnAction}
                                    disabled
                                    fullWidth
                                />
                            </Box>
                            <Box marginLeft={3}>
                                <TextField
                                    label="Reply acceptance by auditor"
                                    variant="outlined"
                                    margin="normal"
                                    style={{ width: 325 }}
                                    value={formData.replyAcceptanceByAuditor}
                                    disabled
                                    fullWidth
                                />
                            </Box>
                            <Box marginLeft={3}>
                                <TextField
                                    label="Reason for rejection"
                                    variant="outlined"
                                    margin="normal"
                                    style={{ width: 325 }}
                                    value={formData.reasonForRejection}
                                    disabled
                                    fullWidth
                                />
                            </Box>
                        </Box>
                        {viewOnly ?
                            <Box marginTop={3} display="flex" alignItems="center">
                                <Button variant="contained" color="info" disableElevation onClick={handleCompileModalClose}>Close</Button>
                            </Box>
                            :
                            <Box marginTop={3} display="flex" alignItems="center">
                                <Button variant="contained" color="success" disableElevation onClick={handleSubmit} style={{ marginRight: 15 }}><SaveIcon style={{ marginRight: 15 }} />
                                    {formData.id === null ? 'Save' : 'Update'}
                                </Button>
                                <Button variant="contained" color="error" disableElevation onClick={handleCompileModalClose}>Cancel</Button>
                            </Box>
                        }

                    </Box>
                </Modal>
                <Modal
                    open={isBestPraticeModalOpen}
                    onClose={handleBestPracticeModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Typography style={{ color: '#1976d2', fontWeight: 'bold'}} id="modal-modal-title" variant="h5" component="h5">{checkListSelected ? checkListSelected.description : ''}</Typography>
                            <Typography style={{ color: '#1976d2', fontWeight: 'bold'}} id="modal-modal-title" variant="h5" component="h5">Code: {machineOrAreaCode}</Typography>
                        </Box>
                        <Typography style={{ color: '#1976d2' }} id="modal-modal-title" variant="h6" component="h6">{indiCompileSelected ? `${indiCompileSelected.item} - ${indiCompileSelected.description}` : ''}</Typography>

                        <Box marginTop={3} display="flex" alignItems="center">
                            <Box display="flex" alignItems="center">
                                <Box>
                                    <TextField
                                        label="Description"
                                        variant="outlined"
                                        margin="normal"
                                        style={{ width: 800 }}
                                        name="description"
                                        value={formBestPracticeData.description}
                                        onChange={handleBPChange}
                                        disabled={viewOnly}
                                        fullWidth
                                        multiline
                                        maxRows={4}
                                        error={Boolean(bestPracticErrors.description)}
                                        helperText={bestPracticErrors.description}
                                    />
                                </Box>
                                <Box marginLeft={3}>
                                    <TextField
                                        label="Created By"
                                        variant="outlined"
                                        margin="normal"
                                        disabled={viewOnly}
                                        style={{ width: 325 }}
                                        fullWidth
                                        value={formBestPracticeData.createdBy}
                                    />
                                </Box>
                                <Box marginLeft={3} paddingTop={1}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Date"
                                            readOnly
                                            value={formBestPracticeData.date}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" flexWrap="wrap">
                            <Box>
                                <Box>
                                    <TextField
                                        label="Previous Situation"
                                        variant="outlined"
                                        margin="normal"
                                        style={{ width: 800 }}
                                        value={formBestPracticeData.previousSituation}
                                        name="previousSituation"
                                        onChange={handleBPChange}
                                        disabled={viewOnly}
                                        fullWidth
                                        multiline
                                        maxRows={4}
                                        error={Boolean(bestPracticErrors.previousSituation)}
                                        helperText={bestPracticErrors.previousSituation}
                                    />
                                </Box>
                                <Box>
                                    <TextField
                                        label="Next Situation"
                                        variant="outlined"
                                        margin="normal"
                                        style={{ width: 800 }}
                                        value={formBestPracticeData.nextSituation}
                                        name="nextSituation"
                                        onChange={handleBPChange}
                                        disabled={viewOnly}
                                        fullWidth
                                        multiline
                                        maxRows={4}
                                        error={Boolean(bestPracticErrors.nextSituation)}
                                        helperText={bestPracticErrors.nextSituation}
                                    />
                                </Box>
                                <Box>
                                    <TextField
                                        label="Expected Benefits"
                                        variant="outlined"
                                        margin="normal"
                                        style={{ width: 800 }}
                                        value={formBestPracticeData.expectedBenefit}
                                        name="expectedBenefit"
                                        onChange={handleBPChange}
                                        disabled={viewOnly}
                                        fullWidth
                                        multiline
                                        maxRows={4}
                                        error={Boolean(bestPracticErrors.expectedBenefit)}
                                        helperText={bestPracticErrors.expectedBenefit}
                                    />
                                </Box>
                            </Box>
                            <Box marginTop={3} marginLeft={3} display="flex" alignItems="start">
                                <Button
                                    style={buttonBPStyle}
                                    role={undefined}
                                    component="label"
                                    disabled={viewOnly}
                                    tabIndex={-1}
                                >
                                    {formBestPracticeData.image !== null ?
                                        <img src={formBestPracticeData.image} alt='' style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                                        : <AddAPhotoIcon style={{ fontSize: '3rem' }} />
                                    }

                                    <VisuallyHiddenInput accept="image/*" id={`image-upload-6`} type="file" onChange={(event) => handleBPImageChange(event)} />
                                </Button>
                            </Box>
                        </Box>
                        {viewOnly ?
                            <Box marginTop={3} display="flex" alignItems="center">
                                <Button variant="contained" color="info" disableElevation onClick={handleBestPracticeModalClose}>Close</Button>
                            </Box>
                            :
                            <Box marginTop={3} display="flex" alignItems="center">
                                <Button variant="contained" color="success" disableElevation onClick={handleBestPracticeSubmit} style={{ marginRight: 15 }}><SaveIcon style={{ marginRight: 15 }} />
                                    {formBestPracticeData.id === null ? 'Save' : 'Update'}
                                </Button>
                                <Button variant="contained" color="error" disableElevation onClick={handleBestPracticeModalClose}>Cancel</Button>
                            </Box>
                        }

                    </Box>
                </Modal>
                <Modal
                    open={isFinalNotesOpened}
                    onClose={handleFinalNotesClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} style={{width: 800} }>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Typography style={{ color: '#1976d2', fontWeight: 'bold' }} id="modal-modal-title" variant="h5" component="h5">Audit's Final Notes</Typography>
                            <Typography style={{ color: '#1976d2', fontWeight: 'bold'}} id="modal-modal-title" variant="h5" component="h5"></Typography>
                        </Box>

                        <Box marginTop={3} display="flex" alignItems="center">
                            <TextField
                                label="Notes"
                                variant="outlined"
                                margin="normal"
                                name="notes"
                                value={formFinalNotesData.notes}
                                onChange={handleFNChange}
                                disabled={viewOnly}
                                fullWidth
                                multiline
                                minRows={8}
                                error={Boolean(finalNotesErrors.notes)}
                                helperText={finalNotesErrors.notes}
                            />
                        </Box>
                        <Box marginTop={3} display="flex" alignItems="center">
                            <Button variant="contained" color="success" disabled={viewOnly} disableElevation onClick={handleFinalNotesSubmit} style={{ marginRight: 15 }}><SaveIcon style={{ marginRight: 15 }} />
                                SAVE
                            </Button>
                            <Button variant="contained" color="error" disableElevation onClick={handleFinalNotesClose}>Cancel</Button>
                        </Box>
                    </Box>
                </Modal>
                {(readOnly === 'false' && !loading) ?
                    <Box marginTop={3}>
                        {/* <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleCheckListRatingsApiSave}>
                        <SaveIcon style={{ marginRight: '15px' }} />Update
                    </Button> */}
                        <LoadingButton
                            style={{ marginRight: '15px' }}
                            loading={loadingButton}
                            loadingPosition='start'
                            startIcon={<SaveIcon />}
                            color='success'
                            variant='contained'
                            disableElevation
                            onClick={handleCheckListRatingsApiSave}
                        >
                            Update
                        </LoadingButton>
                    </Box> :
                    <></>}

            </Box>
            <Box>
                <Dialog
                    open={openDialog}
                    fullWidth
                    maxWidth='md'
                    onClose={handleCloseDialog}
                >
                    <DialogTitle id="d-title" style={{color: '#0070df'}}>
                        Total Indicator Rating
                    </DialogTitle>
                    <DialogContent>
                            {loadingIndicatorTable ? (
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>
                                    <CircularProgress/>
                                </div>
                            ) : (
                                <DataGrid
                                    style={{backgroundColor: appColor.white, marginBottom: 30}}
                                    rows={checkListIndicatorTable}
                                    getRowId={(row) => row.id + row.itemNumber}
                                    columns={dialogColumns}
                                    sx={{
                                        '& .theme--header': {
                                            backgroundColor: '#2082b1',
                                            color: 'white'
                                        },
                                        '& .specific-indicator-color': {
                                            color: 'grey'
                                        },
                                        '& .background-color-rate-red':{
                                            backgroundColor: '#FF8585',
                                        },
                                        '& .background-color-rate-orange':{
                                            backgroundColor: '#FFD1A3',
                                        },
                                        '& .background-color-rate-green':{
                                            backgroundColor: '#DAF2D0',
                                        },
                                    }}
                                />
                            )}
                    </DialogContent>
                    <DialogActions style={{justifyContent: 'space-between', alignItems: 'end', margin: 10}}>
                            <Button variant="contained" color="success" onClick={handleCloseDialog}>
                                OK
                            </Button>
                            <div>
                                <Typography variant="h6" style={{fontWeight: 'bold', marginBottom: 10}}>
                                    Legend:
                                </Typography>
                                <ul style={{marginLeft: 20}}>
                                    <li>
                                        <div className="divInUl">
                                            <div className="coloredBlock" style={{background: '#FF8585'}}>
                                            </div>
                                            Final score between 1-3
                                        </div>
                                    </li>
                                    <li>
                                        <div className="divInUl">
                                            <div className="coloredBlock" style={{background: '#FFD1A3'}}>
                                            </div>
                                            Final score between 3 - 4
                                        </div>
                                    </li>
                                    <li>
                                        <div className="divInUl">
                                            <div className="coloredBlock" style={{ background: '#DAF2D0'}}>
                                            </div>
                                            Final score ≥ 4
                                        </div>
                                    </li>
                                    <li style={{color:'red', maxWidth: 415}}>
                                        <strong style={{textDecoration: 'underline'}}>undefined:</strong> All inputs for the indicator are empty. Please provide at
                                        least one input for one of the empty indicators.
                                    </li>
                                </ul>
                            </div>
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    );
}

export default EditAuditCheckListPage;